import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { 
    InfMunCarregaInterface, infPercurso, ProdutoPredominanteInterface, 
    AutorizacaoDownloadInterface, EstadoInterface, MunicipioInterface, UfPercursoInterface, 
    LacresManifestoInterface,
    infMunDescargaInterface,
    
    infNFesInterface,
    infCTesInterface,
    ModalRodoviario,
    ManifestoState,
    Condutor,
    LacreInterface
} from "../interfaces";



const initialRodoviario: ModalRodoviario =  {
    tracao: {
        condutores: [],
        prop: {},
        capKG: '',
        capM3: '',
        placa: '',
        tpCar: '0',
        tara: '',
        cint: '',
        renavam: '',
        tpRod: '1',
        uf: 'RN'
    },
    reboques: [],
    infANTT: {},
    lacres: []
}


const initialState: ManifestoState = {
    manifesto: {
        nmdf: "1",
        tpemit: 2,
        tptransp: 0,
        modal: 1,
        chave: '',
        serie: '1',
        mod: '58',
        ufini: "RN",
        uffim: "RN",
        vcarga: 0,
        qcarga: 0,
        cunid: "01",
        dhcancel: '',
        dhemi: '',
        dhencer: '',
        protocolo: '',
        situacao: 1,
        infMunCarrega: [],
        infPercurso: [],
        infMunDescarga: [
            
        ],
        prodPred: {},
        authDownload: [],
        infcpl: "",
        infadfisco: "",
        lacres: [],
        modal_rodoviario: initialRodoviario,
        seg: []
    },
    listaErrors: {
        uffim: [],
        ufini: [],
        nmdf: [],
        tpemit: [],
        tptransp: [],
        modal: [],
        infMunCarrega: [],
        infPercurso: [],
        prodPred_cean: [],
        prodPred_ncm: [],
        prodPred_tpcarga: [],
        prodPred_xprod: [],
        modal_rodoviario_tracao_placa: [],
        modal_rodoviario_tracao_tara: [],
        modal_rodoviario_tracao_uf: [],
        modal_rodoviario_tracao_renavam: [],
        modal_rodoviario_tracao_tpcar: [],
        modal_rodoviario_tracao_tpRod: [],

    },

    selectEstados: [{
        id: 0,
        uf: "Selecione",
        name: "",
        cod_ibge: 0,
        pais: { name: 'Brasil' }
    }],
    selectMunicipios: [
        {
            id: 0,
            name: 'Selecione uma UF Inicial',
            estado: {
                uf: 'Selecione'
            },
            capital: 0,
            cod_ibge: 0,
            
        }
    ],
    selectMunicipiosCarregamentos: [
        {
            id: 0,
            name: 'Selecione uma UF Final',
            estado: {
                uf: 'Selecione'
            },
            capital: 0,
            cod_ibge: 0,
            
        }
    ],
    selectMunicipiosDescarregamentos: []
}

const manifestoSlice = createSlice({
    name: "manifesto",
    initialState,
    reducers: {

        clearError: (state, action) => {
            const {[action.payload]:_, ...novalista} = state.listaErrors
            
            return {
                ...state,
                listaErrors: {...novalista}
            }

        },
   
        loadMunicipiosDescargaSelect: (state) => {
            return {
                ...state,
                selectMunicipiosDescarregamentos: state.manifesto.infMunDescarga.map(item => {
                    return {
                        id: item.municipio_id,
                        name: item.name
                    }
                })

            }
        },
        changeTracaoManifesto: (state, action) => {
            if (state.manifesto.modal_rodoviario) {

                if (state.manifesto.modal_rodoviario?.tracao) {

                    return {
                        ...state,
                        manifesto: {
                            ...state.manifesto,
                            modal_rodoviario: {
                                ...state.manifesto.modal_rodoviario,
                                tracao: {...state.manifesto.modal_rodoviario.tracao, ...action.payload},
                            }
                        },
                        
                    }
                }
            }
        },

        changeTracaoProp: (state, action) => {
            if (state.manifesto.modal_rodoviario) {

                if (state.manifesto.modal_rodoviario?.tracao) {

                    return {
                        ...state,
                        manifesto: {
                            ...state.manifesto,
                            modal_rodoviario: {
                                ...state.manifesto.modal_rodoviario,
                                tracao: {
                                    ...state.manifesto.modal_rodoviario.tracao, 
                                    prop: {...state.manifesto.modal_rodoviario.tracao.prop, ...action.payload}
                                },
                            }
                        },
                        
                    }
                }
            }
        },

        clearTracaoProp: (state) => {
            if (state.manifesto.modal_rodoviario) {

                if (state.manifesto.modal_rodoviario?.tracao) {

                    return {
                        ...state,
                        manifesto: {
                            ...state.manifesto,
                            modal_rodoviario: {
                                ...state.manifesto.modal_rodoviario,
                                tracao: {
                                    ...state.manifesto.modal_rodoviario.tracao, 
                                    prop: {}
                                },
                            }
                        },
                        
                    }
                }
            }

        },

        addCondutor: (state, action: PayloadAction<Condutor>) => {
            state.manifesto.modal_rodoviario.tracao.condutores.push(action.payload)
        },

        removeCondutor: (state, action: PayloadAction<string>) => {

            return {
                ...state,
                manifesto: {
                    ...state.manifesto,
                    modal_rodoviario: {
                        ...state.manifesto.modal_rodoviario,
                        tracao: {
                            ...state.manifesto.modal_rodoviario.tracao,
                            condutores: state.manifesto.modal_rodoviario.tracao.condutores.filter((item:Condutor) => item.CPF !== action.payload)
                        }
                    }
                }
            }

        },

        setManifesto: (state, action) => {
            state.manifesto = {...state.manifesto, ...action.payload}

            if (action.payload['modal']) {
                if (action.payload['modal'] !== '1') 
                {
                    state.manifesto = {...state.manifesto, modal_rodoviario: {}}
                }
                
                if (action.payload['modal'] === '1') {
                    state.manifesto = {...state.manifesto, modal_rodoviario: initialRodoviario}
                }
            }
            
        },

        clearManifesto: (state) => {
            return initialState;
        },

        loadManifesto: (state, action) => {
            return {
                ...state,
                manifesto: action.payload
            }
        },
        
        gravarErrors: (state, action) => {
            
            return {
                ...state,
                listaErrors: {...action.payload}
            };
        },

        chamgeProdutoPredominante: (state, action) => {
            return {
                ...state,
                manifesto: {
                    ...state.manifesto,
                    prodPred: {...state.manifesto.prodPred, ...action.payload}
                }
            }
        },

        addLacreManifesto: (state, action: PayloadAction<LacresManifestoInterface>) => {
            state.manifesto.lacres.push(action.payload);
        },

        removerLacreManifesto: (state, action: PayloadAction<string>) => {
            if (state.manifesto.lacres.find(item => item.numero === action.payload)) {
                return {
                    ...state,
                    manifesto: {
                        ...state.manifesto,
                        lacres: state.manifesto.lacres.filter(item => item.numero !== action.payload)
                    }
                }
            }
        },

        addLacreRodoviario: (state, action: PayloadAction<LacresManifestoInterface>) => {
            state.manifesto.modal_rodoviario.lacres.push(action.payload);
        },

        removerLacreRodoviario: (state, action: PayloadAction<string>) => {
            if (state.manifesto.modal_rodoviario.lacres.find((item: LacreInterface) => item.numero === action.payload)) {
                return {
                    ...state,
                    manifesto: {
                        ...state.manifesto,
                        modal_rodoviario: {
                            ...state.manifesto.modal_rodoviario,
                            lacres: state.manifesto.modal_rodoviario.lacres.filter((item: LacreInterface) => item.numero !== action.payload)
                        }
                    }
                }
            }
        },

        addAuthDownload: (state, action: PayloadAction<AutorizacaoDownloadInterface>) => {
            state.manifesto.authDownload.push(action.payload);
        },

        removeAuthDownload: (state, action: PayloadAction<string>) => {
            if (state.manifesto.authDownload.find(item => item.cpfcnpj === action.payload)) {
                return {
                    ...state,
                    manifesto: {
                        ...state.manifesto,
                        authDownload: state.manifesto.authDownload.filter((item: AutorizacaoDownloadInterface) => item.cpfcnpj !== action.payload)
                    }
                }
            }

        },

        addListaMunicipiosCarregamento: (state, action: PayloadAction<InfMunCarregaInterface>) => {
            state.manifesto.infMunCarrega.push({municipio_id: action.payload.municipio_id, name: action.payload.name});
        },
        
        removeMunicipioCarregamento: (state, action: PayloadAction<number>) => {
            
            return {
                ...state,
                listaMunicipiosCarregamento: state.manifesto.infMunCarrega.filter((municipio: InfMunCarregaInterface) => municipio.municipio_id !== action.payload),
                manifesto: {
                    ...state.manifesto,
                    infMunCarrega: state.manifesto.infMunCarrega.filter((municipio: InfMunCarregaInterface) => municipio.municipio_id !== action.payload)
                }
            }
        },

        addListaUFPercurso: (state, action: PayloadAction<UfPercursoInterface>) => {
            state.manifesto.infPercurso.push({estado_id: action.payload.id, uf: action.payload.uf});
        },
       

        removeListaUFPercurso: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                manifesto: {
                    ...state.manifesto,
                    infPercurso: state.manifesto.infPercurso.filter((estado: infPercurso) => estado.estado_id !== action.payload)
                }
            }
        },

        setEstados: (state, action: PayloadAction<EstadoInterface[]>) => {
            return {
                ...state,
                selectEstados: [...state.selectEstados, ...action.payload]
            }
        },

        setSelectMunicipios: (state, action: PayloadAction<MunicipioInterface[]>) => {
            state.selectMunicipios = action.payload;
        },

        setSelectMunicipiosCarregamento: (state, action: PayloadAction<MunicipioInterface[]>) => {
            state.selectMunicipiosCarregamentos = action.payload;
        },
      
        setinfMunCarrega: (state, action: PayloadAction<InfMunCarregaInterface>) => {
            state.manifesto.infMunCarrega.push(action.payload);
        },

        removeInfMunCarrega: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                infMunCarrega: state.manifesto.infMunCarrega.filter((municipio: InfMunCarregaInterface) => municipio.municipio_id !== action.payload)
            }
        },

        setinfPercurso: (state, action: PayloadAction<infPercurso>) => {
            state.manifesto.infPercurso.push(action.payload);
        },

        removeInfPercurso: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                infPercurso: state.manifesto.infPercurso.filter((estado: infPercurso) => estado.estado_id !== action.payload)
            }
        },

        setProdPred: (state, action: PayloadAction<ProdutoPredominanteInterface>) => {
            state.manifesto.prodPred = action.payload
        },

        removeProdPred: (state) => {
            return {
                ...state,
                prodPred: {}
            }
        },

        addListaMunicipiosDescarregamento: (state, action: PayloadAction<infMunDescargaInterface>) => {
         
            state.manifesto.infMunDescarga.push({
                municipio_id: action.payload.municipio_id,
                name: action.payload.name,
                infNFe: [],
                infCTe: [],

            });

            if (!state.selectMunicipiosDescarregamentos.find(item => item.id === action.payload.municipio_id)) {
                
                state.selectMunicipiosDescarregamentos.push({id: action.payload.municipio_id, name: action.payload.name});
            }
            
        },
        
        removeListaMunicipioDescarregamento: (state, action: PayloadAction<number>) => {
            
            return {
                ...state,
                manifesto: {
                    ...state.manifesto,
                    infMunDescarga: state.manifesto.infMunDescarga.filter((municipio) => municipio.municipio_id !== action.payload)
                },
                selectMunicipiosDescarregamentos: state.selectMunicipiosDescarregamentos.filter(item => item.id !== action.payload)
            }
        },

        addNFe: (state, action: PayloadAction<infNFesInterface>) => {
            const index = state.manifesto.infMunDescarga.findIndex((item) => item.municipio_id === action.payload.municipio_id)

            if (index !== -1) {
                state.manifesto.infMunDescarga[index].infNFe?.push(action.payload)
            }
            
        },

        removeNFe: (state, action: PayloadAction<{municipio_id: number; chNFe: string}>) => {
            
            const munDescarga = state.manifesto.infMunDescarga.filter((item) => item.municipio_id === action.payload.municipio_id)
            const index = state.manifesto.infMunDescarga.findIndex((item) => item.municipio_id === action.payload.municipio_id)
            let newLista

            if (index !== -1) {
               newLista = state.manifesto.infMunDescarga[index].infNFe?.filter((item) => item.chNFe !== action.payload.chNFe) || []

                return {
                    ...state,
                        manifesto: {
                            ...state.manifesto,
                                infMunDescarga: [
                                    ...state.manifesto.infMunDescarga.filter(item => item.municipio_id !== action.payload.municipio_id), 
                                    { 
                                        municipio_id: action.payload.municipio_id, 
                                        name: munDescarga[0].name, 
                                        infNFe: newLista,
                                        infCTe: []
                                    }
                                ]
                        }
                }
            }
          
        },

        clearNFe: (state) => {
            return {
                ...state,
                manifesto: {
                    ...state.manifesto,
                    infNFe: []
                },
            }
        },
        
        clearCTe: (state) => {
            return {
                ...state,
                manifesto: {
                    ...state.manifesto,
                    infCTe: []
                },
            }
        },
        
        addCTe: (state, action: PayloadAction<infCTesInterface>) => {
            const index = state.manifesto.infMunDescarga.findIndex((item) => item.municipio_id === action.payload.municipio_id)

            if (index !== -1) {
                state.manifesto.infMunDescarga[index].infCTe?.push(action.payload)
            }
            
        },

        removeCTe: (state, action: PayloadAction<{municipio_id: number; chCTe: string}>) => {
            const munDescarga = state.manifesto.infMunDescarga.filter((item) => item.municipio_id === action.payload.municipio_id)
            const index = state.manifesto.infMunDescarga.findIndex((item) => item.municipio_id === action.payload.municipio_id)
            let newLista

            if (index !== -1) {
               newLista = state.manifesto.infMunDescarga[index].infCTe?.filter((item) => item.chCTe !== action.payload.chCTe) || []

                return {
                    ...state,
                        manifesto: {
                            ...state.manifesto,
                                infMunDescarga: [
                                    ...state.manifesto.infMunDescarga.filter(item => item.municipio_id !== action.payload.municipio_id), 
                                    { 
                                        municipio_id: action.payload.municipio_id, 
                                        name: munDescarga[0].name, 
                                        infCTe: newLista,
                                        infNFe: []
                                    }
                                ]
                        }
                }
            }
           
        },

    }
});

export const { setEstados, setManifesto, setinfMunCarrega, removeInfMunCarrega, 
    setinfPercurso, removeInfPercurso, setProdPred, removeProdPred, addAuthDownload, 
    removeAuthDownload ,setSelectMunicipios,addListaMunicipiosCarregamento, 
    removeMunicipioCarregamento, addListaUFPercurso, removeListaUFPercurso, 
    gravarErrors, clearManifesto, chamgeProdutoPredominante, loadManifesto,
    addLacreManifesto, removerLacreManifesto, setSelectMunicipiosCarregamento,
    addListaMunicipiosDescarregamento, removeListaMunicipioDescarregamento,
    addNFe, removeNFe, clearNFe, addCTe, removeCTe, clearCTe, 
    changeTracaoManifesto, clearError, changeTracaoProp, clearTracaoProp, 
    addCondutor, removeCondutor, addLacreRodoviario, removerLacreRodoviario,
    loadMunicipiosDescargaSelect
} = manifestoSlice.actions;

export default manifestoSlice.reducer;