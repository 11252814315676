import { useContext, useEffect, useState} from "react";
import { EmpresaInterface as iData } from "../../interfaces";
import  empresaService   from "../../services/EmpresaService";
import { Col, Form as BsForm } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { JAlerta, JCaption, JCard, JCardBody, JHeader, JPaginacao, JTable, JTBody, JTHead } from "../../components/Components";
import { AuthContext } from "../../contexts/AuthContext";


const initial = {
    id: "",
    name: "",
    cpfcnpj: "",
    xfant: "",
    ie: "",
    xlg: "",
    nro: "",
    xcpl: "",
    cep: "",
    xbairro: "",
    estado_id: 0,
    municipio_id: 0,
    fone: "",
    celular: "",
    email: "",
    certificado: "",
    imagem: "",
    tpamb: 0,

    active: true
};

export default function IndexEmpresa() {
    const [dados, setDados] = useState<iData[]>([]);
    const [showSpinner, setShowSpinner] = useState(true);
    const [showError, setShowError] = useState(false);
    const [showMessageError, setShowMessageError] = useState('');

    const [search, setSearch] = useState('');
    const [meta, setMeta] = useState<any>(null)
    const [links, setLinks] = useState<any>(null)
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown)
        searchFilter();

        return () => {
            window.removeEventListener("keydown", handleKeyDown)
        }
    }, [search]);


    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Insert') {
            navigate('/profile/create');
        }
    }
    
    const searchFilter = async (page: string | null = null) =>  {
  
       setShowSpinner(true);
        
        await empresaService.getAllPaginate(search, page).then( response => {
            setDados(response.data);
            setLinks(response.links);
            setMeta(response.meta);
            setShowSpinner(false);
            
        }).catch(err => {
            if (err.response.status === 403) {
                authContext?.setShowError(true)
                authContext?.setShowMessageError(err.response.data.message);
                navigate('/');
            }
            setShowSpinner(false);
        });

    }

    const handlePageChange = (page: string | null = null) => {
        searchFilter(page)
    }

    const handleActivate = (id: number | string, statusAtual: boolean) => {
        const status = statusAtual;
        const element = document.getElementsByName("active"+ id)[0] as HTMLInputElement;
        
        element.disabled = true;
        if (status) {
            
            empresaService.deactivate(id).then(response => {
                
                setDados(dados.map(data => data.id === id ? { ...data, active: false } : data));
                element.disabled = false;
            }).catch(err => {
            if (err.response.status === 403) {
                setShowError(true)
                setShowMessageError(err.response.data.message);
                element.disabled = false;
            }
            });
        } else {
           
            empresaService.activate(id).then(response => {
                setDados(dados.map(data => data.id === id ? { ...data, active: true } : data));
                element.disabled = false;
            }).catch(err => {
                setShowError(true)
                setShowMessageError(err.response.data.message);
                element.disabled = false;
            });
        }
    }


 
    
    return (
        <>
        <JAlerta showError={showError} close={setShowError} messageError={showMessageError} /> 
            <div className="row d-flex justify-content-between align-items-start g-2">
                <Col md>
                    <JCard className="shadow card-outline">
                        <JHeader  
                            urlCreate="/empresa/create"
                            search={search}
                            setSearch={setSearch}
                            showSpinner={showSpinner}
                            permissionCreate={authContext?.userLogado.is_staff || authContext?.userLogado.is_superuser}
                            permissionSearch={authContext?.userLogado.is_staff || authContext?.userLogado.is_superuser}
                        >
                            
                        </JHeader>
                        <JCardBody>
                            <JCaption>
                                Lista de empresas
                                <JPaginacao 
                                    links={links ?? {}}
                                    meta={meta ?? {}}
                                    handlePageChange={handlePageChange} />
                            </JCaption>
                            <JTable >
                            
                                <JTHead>
                                    <tr>
                                        <th style={{ width: '5rem' }} className="text-center">Ações</th>
                                        <th  style={{ width: '7rem' }} >Tipo Empresa</th>
                                        <th>Nome</th>
                                        <th>Fantasia</th>
                                        <th>CNPJ</th>
                                        <th>IE</th>
                                        <th>Celular</th>
                                        <th>Email</th>
                                        <th className="text-center">Status</th>
                                    </tr>
                                </JTHead>
                                <JTBody>
                                    {dados?.map((data) => (
                                        <tr key={data.id}>
                                            <td className="text-center">
                                                <Link className="text-primary" to={`/empresa/${data.id}`}>
                                                    <FontAwesomeIcon icon={faPencil} />
                                                </Link>
                                                
                                            </td>
                                            <td>{parseInt(data.cpfcnpj.substr(8,4)) === 1 ? 'Matriz' : 'Filial'}</td>
                                            <td>{data.name}</td>
                                            <td>{data.xfant}</td>
                                            <td>{data.cpfcnpj}</td>
                                            <td>{data.ie}</td>
                                            <td>{data.celular}</td>
                                            <td>{data.email}</td>
                                            {/* <td>{data.active ? 'Ativo' : 'Inativo'}</td> */}
                                            <td className="text-center">
                                                <BsForm.Switch type="switch"  name={"active" + data.id}  checked={data.active}   
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleActivate(data.id, data.active)} />
                                            </td>
                                        </tr>
                                    ))}                           
                                </JTBody>
                            </JTable>
                        </JCardBody>
                    </JCard>
                </Col>
            </div>
        </>
    );
};

