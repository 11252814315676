import React, {useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faSave, faAngleDoubleRight, faAngleDoubleLeft} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import userService from "../../services/UserService";
import { JAlerta } from "../../components/Components";
import  auxService  from "../../services/AuxService";
import { 
    PermissionInterface, 
    ProfileInterface, 
    EmpresaInterface,  
    UserFormInterface as iData 
} from "../../interfaces";

const initial = {
    id: '',
    name: '',
    email: '',
    password: '',
    is_superuser: 0,
    empresas: [],
    permissions: [],
    profiles: [],
    active: true,
}

const initialFieldsError = {
  name: [], 
  email: [],
  password: [],
}


export default function FormUser() {
    const navigate = useNavigate()
    const { id } = useParams<{id: string}>();
    const [showError, setShowError] = useState(false);
    const [showMessageError, setShowMessageError] = useState('');
    const [errors, setErrors] = useState(initialFieldsError);
    const [data, setData] = useState<iData>(initial)
   
    const [permissions, setPermissions] = useState<PermissionInterface[]>([])
    const [permissionsFiltrado, setPermissionsFiltrado] = useState<PermissionInterface[]>([])
    const [aplicados, setAplicados] = useState<PermissionInterface[]>([])
    const [apliFilter, setApliFilter] = useState<PermissionInterface[]>([])
    
    const [profileDisponiveis, setProfileDisponiveis] = useState<ProfileInterface[]>([])
    const [profileDisponiveisFiltrado, setProfileDisponiveisFiltrado] = useState<ProfileInterface[]>([])
    const [profileAplicados, setProfileAplicados] = useState<ProfileInterface[]>([])
    const [profileAplicadosFiltrado, setProfileAplicadosFiltrado] = useState<ProfileInterface[]>([])

    const [empresas, setEmpresas] = useState<EmpresaInterface[]>([])
    const [empresasFiltrado, setEmpresasFiltrado] = useState<EmpresaInterface[]>([])
    const [empresasAplicadas, setEmpresasAplicadas] = useState<EmpresaInterface[]>([])
    const [empresasAplicadasFiltrado, setEmpresasAplicadasFiltrado] = useState<EmpresaInterface[]>([])

    useEffect(() => {
        
        if (id) {
          userService.get(id)
            .then(data => {
                
                setData(data);
                setPermissions([...data.not_permissions.map((permission:PermissionInterface) => ({ id:permission.id, name:permission.name}))] );
                setPermissionsFiltrado([...data.not_permissions.map((permission:PermissionInterface) => ({ id:permission.id, name:permission.name}))]);
                setAplicados([...data.permissions.map((permission:PermissionInterface) => ({ id:permission.id, name:permission.name}))] );
                setApliFilter([...data.permissions.map((permission:PermissionInterface) => ({ id:permission.id, name:permission.name}))] );

                setProfileDisponiveis([...data.not_profiles.map((profile:ProfileInterface) => ({id:profile.id, name:profile.name}))]);
                setProfileDisponiveisFiltrado([...data.not_profiles.map((profile:ProfileInterface) => ({id:profile.id, name:profile.name}))]);
                setProfileAplicados([...data.profiles.map((profile:ProfileInterface) => ({id:profile.id, name:profile.name}))]);
                setProfileAplicadosFiltrado([...data.profiles.map((profile:ProfileInterface) => ({id:profile.id, name:profile.name}))]);

                setEmpresas([...data.not_empresas.map((empresa:EmpresaInterface) => ({id:empresa.id, name:empresa.name, cpfcnpj:empresa.cpfcnpj}))]);
                setEmpresasFiltrado([...data.not_empresas.map((empresa:EmpresaInterface) => ({id:empresa.id, name:empresa.name, cpfcnpj:empresa.cpfcnpj}))]);
                setEmpresasAplicadas([...data.empresas.map((empresa:EmpresaInterface) => ({id:empresa.id, name:empresa.name, cpfcnpj:empresa.cpfcnpj}))]);
                setEmpresasAplicadasFiltrado([...data.empresas.map((empresa:EmpresaInterface) => ({id:empresa.id, name:empresa.name, cpfcnpj:empresa.cpfcnpj}))]);
            })

            .catch(err => {
                navigate('/user')
            })
        } else {
            auxService.getPermissions().then(data => {
                setPermissions(data)
                setPermissionsFiltrado(data);
            })

            auxService.getProfiles().then(data => {
                setProfileDisponiveis(data)
                setProfileDisponiveisFiltrado(data)
            })

            auxService.getEmpresas().then(data => {
                setEmpresas(data)
                setEmpresasFiltrado(data)
            })
        }
    }, [id, navigate])

    //handles
    const handleSubmit = (e: any) => {
        e.preventDefault();
        data.permissions = aplicados.map((permission) => permission.name);
        data.profiles = profileAplicados.map((profile) => profile.id);
        data.empresas = empresasAplicadas.map((empresa) => empresa.id);

        if (id) {
             
            userService.update(id, data).then( data => {
                navigate('/user')
            }).catch(err => {
            
                if (err.response.status === 422) {
                    setErrors(err.response.data)
                }
                if (err.response.status === 403) {
                    setShowError(true)
                    setShowMessageError(err.response.data)
                }
                navigate(`/user/${id}`)
            })
            return;
        } else {
            
            userService.create(data).then( data => {
                navigate('/user')
            }).catch(err => {
                if (err.response.status === 422) {
                    setErrors(err.response.data)
                }
                if (err.response.status === 403) {
                    setShowError(true)
                    setShowMessageError(err.response.data)
                }
                navigate("/user/create")
            })
        }
    }

    const handleFilterDisponiveis = (e: any) => {
        setPermissionsFiltrado([...permissions.filter(permission => permission.name.includes(e.target.value))])
    }

    const handleFilterAplicados = (e: any) => {
        setApliFilter([...aplicados.filter(permission => permission.name.includes(e.target.value))])
    }

    const handleFilterProfileDisponiveis = (e: any) => {
        setProfileDisponiveisFiltrado([...profileDisponiveis.filter(profile => profile.name.includes(e.target.value))])
    }

    const handleFilterProfileAplicados = (e: any) => {
        setProfileAplicadosFiltrado([...profileAplicados.filter(profile => profile.name.includes(e.target.value))])
    }

    const handleFilterEmpresas = (e: any) => {
        setEmpresasFiltrado([...empresas.filter(empresa => empresa.name.includes(e.target.value))])
    }

    const handleFilterEmpresasAplicadas = (e: any) => {
        setEmpresasAplicadasFiltrado([...empresasAplicadas.filter(empresa => empresa.name.includes(e.target.value))])
    }


    const handleChange = (e: any) => {
        const {name, value} = e.target;
        
        setData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    //functions
    const addPermission = (permission: PermissionInterface) => {

        
        if (permission) {       
            setPermissions((prev) => prev.filter((p) => p.id !== permission.id));
            setPermissionsFiltrado((prev) => prev.filter((p) => p.id !== permission.id));
            setAplicados((prev) => [...prev, permission]);
            setApliFilter((prev) => [...prev, permission]);
        }
    };
    
    const removePermission = (permission: PermissionInterface) => {
        setPermissions((prev) => [...prev, permission]);
        setPermissionsFiltrado((prev) => [...prev, permission]);

        setAplicados((prev) => prev.filter((p) => p.id !== permission.id));
        setApliFilter((prev) => prev.filter((p) => p.id !== permission.id));
    };

    const addProfile = (profile: ProfileInterface) => {
        
        if (profile) {
            setProfileDisponiveis((prev) => prev.filter((p) => p.id !== profile.id));
            setProfileDisponiveisFiltrado((prev) => prev.filter((p) => p.id !== profile.id));

            setProfileAplicados((prev) => [...prev, profile]);
            setProfileAplicadosFiltrado((prev) => [...prev, profile]);
        }
    };
    
    const removeProfile = (profile: ProfileInterface) => {
        setProfileDisponiveis((prev) => [...prev, profile]);
        setProfileDisponiveisFiltrado((prev) => [...prev, profile]);

        setProfileAplicados((prev) => prev.filter((p) => p.id !== profile.id));
        setProfileAplicadosFiltrado((prev) => prev.filter((p) => p.id !== profile.id));
    };

    const addEmpresa = (empresa: EmpresaInterface) => {
        if (empresa) {
            setEmpresas((prev) => prev.filter((p) => p.id !== empresa.id));
            setEmpresasFiltrado((prev) => prev.filter((p) => p.id !== empresa.id));

            setEmpresasAplicadas((prev) => [...prev, empresa]);
            setEmpresasAplicadasFiltrado((prev) => [...prev, empresa]);
        }
    };

    const removeEmpresa = (empresa: EmpresaInterface) => {
        setEmpresas((prev) => [...prev, empresa]);
        setEmpresasFiltrado((prev) => [...prev, empresa]);

        setEmpresasAplicadas((prev) => prev.filter((p) => p.id !== empresa.id));
        setEmpresasAplicadasFiltrado((prev) => prev.filter((p) => p.id !== empresa.id));
    };

    return (
        <div className="mb-2">
            <JAlerta showError={showError} close={setShowError} messageError={showMessageError} /> 

            <Form onSubmit={handleSubmit} noValidate>
                <Card className="card-success card-outline">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <div className="my-0">
                            <Button variant={id ? 'success': 'primary'} type="submit"><FontAwesomeIcon icon={faSave} /> {id ? 'Salvar': 'Criar' }</Button>
                            <Link to="/user" className="btn btn-secondary ms-1"> <FontAwesomeIcon icon={faArrowLeft} /> Voltar</Link>
                        </div>
                        <div className="h5 py-0 m-0 text-muted">{id ? 'Editar' : 'Novo'} Usuário</div>
                    </Card.Header>

                    <Card.Body>
                        <Row className="g-2">
                          
                            <Col md>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Nome Usuario</Form.Label>
                                    <Form.Control maxLength={50} name="name" value={data.name} 
                                        type="text" onChange={handleChange}  />

                                    {errors.name  && (
                                        <ul className="errorfield">
                                            {errors.name.map((error, index) => (
                                                <li key={index} className="text-danger">{error}</li>
                                            ))}
                                        </ul>
                                    )}
                                </Form.Group>
                            </Col>

                            <Col md>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control maxLength={50} name="email" value={data.email} readOnly={id ? true : false}
                                    style={{backgroundColor: id ? '#e9ecef' : ''}}
                                        type="text" onChange={handleChange}  />

                                    {errors.email  && (
                                        <ul className="errorfield">
                                            {errors.email.map((error, index) => (
                                                <li key={index} className="text-danger">{error}</li>
                                            ))}
                                        </ul>
                                    )}
                                </Form.Group>
                            </Col>
                                         
                                {!id && (

                                    <Col md>
                                        <Form.Group className="mb-3" controlId="password">
                                            <Form.Label>Senha</Form.Label>
                                            <Form.Control maxLength={50} minLength={8} name="password" value={data.password} 
                                                type="password" onChange={handleChange}  />

                                            {errors.password  && (
                                                <ul className="errorfield">
                                                    {errors.password.map((error, index) => (
                                                        <li key={index} className="text-danger">{error}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </Form.Group>
                                    </Col>
                                )}
                            </Row>
                            
                            <Row>
             
                                <Col md>
                                    <Form.Group className="mb-3" controlId="active">
                                        <Form.Switch type="switch" name="active" label="Usuário ativo?" checked={data.active} 
                                            onChange={(e) => setData({...data, active: e.target.checked})} />
                                    </Form.Group>
                                </Col>

                            </Row>
                       
                            <Row>
                                <Col md={6} className="mt-2">
                                    <Row>
                                        <Col md className="">
                                                <h5>Permissões por usuário</h5>
                                                <hr className="mt-1" />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={5} >
                                            <div className="d-flex justify-content-between align-items-center gap-2 mb-3 mx-2">
                                                <h5 className="text-muted">Disponíveis</h5>
                                                <Form.Control type="search"  placeholder="Buscar" onChange={(e) => handleFilterDisponiveis(e)}/>

                                            </div>
                                            <Form.Select multiple id="multiSelect" style={{height: '200px'}} 
                                                onDoubleClick={(e: React.MouseEvent<HTMLSelectElement>) => {
                                                   
                                                    const permission = permissions.find((item: PermissionInterface) => {
                                                        if (parseInt(item.id) === parseInt((e.target as HTMLSelectElement).value)) {
                                                            return item;
                                                        }  
                                                        return null; 
                                                    })

                                                    if (permission) {
                                                        addPermission(permission)
                                                    }
                                                }}
                                            >
                                            {permissionsFiltrado.map((permission) => (
                                                <option value={permission.id} key={permission.id}>
                                                {permission.name}
                                                </option>
                                            ))}
                                            </Form.Select>
                                        
                                        </Col>

                                        <Col md={'auto'} className="d-flex flex-column align-items-center justify-content-center">
                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                className="mb-2 mt-2"
                                                disabled={permissionsFiltrado.length === 0}
                                                onClick={() =>
                                                    permissionsFiltrado.forEach((permission) => addPermission(permission))
                                                }><FontAwesomeIcon icon={faAngleDoubleRight} /></Button>

                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                className="mb-2"
                                                disabled={apliFilter.length === 0}
                                                onClick={() => apliFilter.forEach((permission) => removePermission(permission))}>
                                                    <FontAwesomeIcon icon={faAngleDoubleLeft} /></Button>
                                        </Col>

                                        <Col md={6} >
                                            <div className="d-flex justify-content-between align-items-center gap-2 mb-3 mx-2">
                                                <h5 className="text-muted">Aplicadas</h5>
                                                <Form.Control type="search"  placeholder="Buscar" onChange={(e) => handleFilterAplicados(e)}/>

                                            </div>
                                            <Form.Select multiple id="multiSelect" style={{height: '200px'}}
                                                onDoubleClick={(e: React.MouseEvent<HTMLSelectElement>) => {
                                                removePermission(aplicados.find((p) => p.name === (e.target as HTMLSelectElement).value) as PermissionInterface)
                                            }}
                                            >
                                            {apliFilter.map((permission) => (
                                                <option value={permission.name} key={permission.name}>
                                                {permission.name}
                                                </option>
                                            ))}
                                            </Form.Select>
                                        
                                        </Col>
                                    </Row>
                                </Col>
                                
                                <Col md={6} className="mt-2">
                                    <Row>
                                        <Col md >
                                            <h5>Permissões por perfil</h5>
                                            <hr className="mt-1" />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={5} >
                                            <div className="d-flex justify-content-between align-items-center gap-2 mb-3 mx-2">
                                                <h5 className="text-muted">Disponíveis</h5>
                                                <Form.Control type="search"  placeholder="Buscar" onChange={(e) => handleFilterProfileDisponiveis(e)}/>

                                            </div>
                                            <Form.Select multiple id="multiSelect" style={{height: '200px'}} 
                                                onDoubleClick={(e: React.MouseEvent<HTMLSelectElement>) => {

                                                    const profile = profileDisponiveisFiltrado.find((item: ProfileInterface) => {
                                                        if (parseInt(item.id) === parseInt((e.target as HTMLSelectElement).value)) {
                                                            return item;
                                                        }
                                                        return null;
                                                    })

                                                    if (profile) {
                                                        addProfile(profile)
                                                    }

                                                }}
                                            >
                                            {profileDisponiveisFiltrado.map((profile) => (
                                                <option value={profile.id} key={profile.name}>
                                                {profile.name}
                                                </option>
                                            ))}
                                            </Form.Select>
                                        
                                        </Col>

                                        <Col md={'auto'} className="d-flex flex-column align-items-center justify-content-center">
                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                className="mb-2 mt-2"
                                                disabled={profileDisponiveisFiltrado.length === 0}
                                                onClick={() =>
                                                    profileDisponiveisFiltrado.forEach((profile) => addProfile(profile))
                                                }
                                            >
                                                <FontAwesomeIcon icon={faAngleDoubleRight} />
                                            </Button>

                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                className="mb-2"
                                                disabled={profileAplicadosFiltrado.length === 0}
                                                onClick={() => profileAplicadosFiltrado.forEach((profile) => removeProfile(profile))}
                                            >
                                            <FontAwesomeIcon icon={faAngleDoubleLeft} />
                                            </Button>
                                        </Col>

                                        <Col md={6} >
                                            <div className="d-flex justify-content-between align-items-center gap-2 mb-3 mx-2">
                                                <h5 className="text-muted">Aplicadas</h5>
                                                <Form.Control type="search"  placeholder="Buscar" onChange={(e) => handleFilterProfileAplicados(e)}/>

                                            </div>
                                            <Form.Select multiple id="multiSelect" style={{height: '200px'}}
                                                onDoubleClick={(e: React.MouseEvent<HTMLSelectElement>) => {
                                                    const profile = profileAplicadosFiltrado.find((p: ProfileInterface) => {
                                                        if ( parseInt(p.id) === parseInt((e.target as HTMLSelectElement).value)) {
                                                            return p;
                                                        }
                                                        return null;
                                                    })

                                                    if (profile) {
                                                        removeProfile(profile)
                                                    }
                                                }}
                                            >
                                            {profileAplicadosFiltrado.map((profile) => (
                                                <option value={profile.id} key={profile.name}>
                                                {profile.name}
                                                </option>
                                            ))}
                                            </Form.Select>
                                        
                                        </Col>
                                    </Row>       
                                </Col>
                            </Row>

                            <Row>
                                <Col md className="mt-4">
                                    <h5>Empresas</h5>
                                    <hr className="mt-1" />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={5} className="mt-2">
                                    <div className="d-flex justify-content-between align-items-center gap-2 mb-3 mx-2">
                                        <h5 className="text-muted">Disponíveis</h5>
                                        <Form.Control type="search"  placeholder="Buscar" onChange={(e) => handleFilterEmpresas(e)}/>

                                    </div>
                                    <Form.Select multiple id="multiSelect" style={{height: '200px'}}
                                 
                                        onDoubleClick={(e: React.MouseEvent<HTMLSelectElement>) => {
                                            const empresa = empresasFiltrado.find((item: EmpresaInterface) => {
                                                if (item.id === parseInt((e.target as HTMLSelectElement).value)) {
                                                    return item;
                                                }
                                                return null;
                                            })

                                            if (empresa) {
                                                addEmpresa(empresa)
                                            }
                                        }}

                                    >
                                    {empresasFiltrado.map((empresa) => (
                                        <option value={empresa.id} key={empresa.name}>
                                            {empresa.name} <strong>CNPJ: {empresa.cpfcnpj}</strong>
                                        </option>
                                    ))}
                                    </Form.Select>
                                </Col>

                                <Col md={'auto'} className="d-flex flex-column align-items-center justify-content-center">
                                    <Button variant="secondary" size="sm" className="mb-2 mt-2" disabled={empresasFiltrado.length === 0} 
                                        onClick={() => empresasFiltrado.forEach((empresa) => addEmpresa(empresa))}>
                                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                                    </Button>
                                    <Button variant="secondary" size="sm" className="mb-2" disabled={empresasAplicadasFiltrado.length === 0} 
                                        onClick={() => empresasAplicadasFiltrado.forEach((empresa) => removeEmpresa(empresa))}>
                                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                                    </Button>

                                </Col>

                                <Col md={6} className="mt-2">
                                    <div className="d-flex justify-content-between align-items-center gap-2 mb-3 mx-2">
                                        <h5 className="text-muted">Aplicadas</h5>
                                        <Form.Control type="search"  placeholder="Buscar" onChange={(e) => handleFilterEmpresasAplicadas(e)}/>
                                    </div>
                                    <Form.Select multiple id="multiSelect" style={{height: '200px'}}
                                        onDoubleClick={(e: React.MouseEvent<HTMLSelectElement>) => {
                                            const empresa = empresasAplicadasFiltrado.find((item: EmpresaInterface) => {
                                                if (item.id === parseInt((e.target as HTMLSelectElement).value)) {
                                                    return item;
                                                }
                                                return null;
                                            })
                                            
                                            if (empresa) {
                                                removeEmpresa(empresa)
                                            }
                                        }}
                                    >
                                    {empresasAplicadasFiltrado.map((empresa) => (
                                        <option value={empresa.id} key={empresa.name} >
                                            {empresa.name} <strong>CNPJ: {empresa.cpfcnpj}</strong>
                                        </option>
                                    ))}
                                    </Form.Select>
                                </Col>
                            </Row>
                            
                    </Card.Body>
                </Card>
            </Form>
        </div>
    );
}
