import {useState, useEffect, useRef } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { EstadoInterface, EmpresaInterface as iData, MunicipioInterface } from "../../interfaces";
import empresaService from "../../services/EmpresaService";
import { JAlerta } from "../../components/Components";
import  auxService  from "../../services/AuxService";
import { handleNumberOnly } from "../../utils/utils";

const initial = {
    id: '',
    name: '',
    cpfcnpj: '',
    xfant: '',
    ie: '',
    xlgr: '',
    nro: '',
    xcpl: '',
    cep: '',
    xbairro: '',
    estado_id: 0,
    municipio_id: 0,
    fone: '',
    celular: '',
    email: '',
    certificado: '',
    imagem: '',
    tpamb: 2,
    active: true
}


const initialFieldsError = {
  name: [], 
  xfant: [],
  cpfcnpj: [],
  ie: [],
  xlgr: [],
  nro: [],
  cep: [],
  xcpl: [],
  xbairro: [],
  estado_id: [],
  municipio_id: [],
  fone: [],
  celular: [],
  email: [],

}

export default function FormEmpresa() {
    const navigate = useNavigate()
    const { id } = useParams<{id: string}>();
    const [showError, setShowError] = useState(false);
    const [showMessageError, setShowMessageError] = useState('');
    const [errors, setErrors] = useState(initialFieldsError);
    const [data, setData] = useState<iData>(initial)
    const [estados, setEstados] = useState<EstadoInterface[]>([{
        id: 0, 
        uf: 'Selecione', 
        name: 'Selecione', 
        cod_ibge: 0,
        pais: { name: 'Brasil' }
        
    }]);
    const [municipios, setMunicipios] = useState<MunicipioInterface[]>([]);
    const municipioRef = useRef<HTMLSelectElement>(null);
    const cpfcnpjRef = useRef<HTMLInputElement>(null);
    

    useEffect(() => {
        auxService.getEstados().then(data => {
            setEstados([...estados, ...data])
        })

        if (id) {
            cpfcnpjRef.current?.setAttribute('disabled', '');
          empresaService.get(id)
            .then(data => {
                setData(data);
                auxService.getMunicipios(data.estado_id).then(res => {
                    setMunicipios(res)
                })    
            })
            .catch(err => navigate('/empresa'))
            
        } else {
            municipioRef.current?.setAttribute('disabled', '');
        }
    }, [id])

    //handles
    const handleSubmit = (e: any) => {
        e.preventDefault();
        

        if (id) {
            
            
            empresaService.update(id, data).then( data => {
                navigate('/empresa')
            }).catch(err => {
                
                if (err.response.status === 422) {
                    setErrors(err.response.data)
                }
                if (err.response.status === 403) {
                    setShowError(true)
                    setShowMessageError(err.response.data.message)
                }
                navigate(`/empresa/${id}`)
            })
            return;
        } else {
            
            
            empresaService.create(data).then( data => {
                navigate('/empresa')
            }).catch(err => {
                
                if (err.response.status === 422) {
                    setErrors(err.response.data)
                }
                if (err.response.status === 403) {
                    setShowError(true)
                    setShowMessageError(err.response.data.message)
                }
                navigate("/empresa/create")
            })
        }
    }


    const handleChange = (e: any) => {
        const {name, value} = e.target;
        
        setData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const handleChangeEstado = (e: any) => {
        const {name, value} = e.target;
        municipioRef.current?.setAttribute('disabled', '');

        if (value > 0) {
        auxService.getMunicipios(value).then(data => {
            setMunicipios(data)
            setData((prev) => ({
                ...prev,
                municipio_id: data.filter((municipio: MunicipioInterface) => municipio.capital === 1)[0]?.id || 0
            }))
            municipioRef.current?.removeAttribute('disabled');
        }).catch(err => {
                municipioRef.current?.removeAttribute('disabled');
            })
        } else {
            municipioRef.current?.setAttribute('disabled', '');
            
            setMunicipios([])
            setData((prev) => ({
                ...prev,
                municipio_id: 0
            }))
        }


        setData((prev) => ({
            ...prev,
            [name]: value
        }))
        
    }



    return (
        <div >
            <JAlerta showError={showError} close={setShowError} messageError={showMessageError} /> 

            <Form onSubmit={handleSubmit} noValidate>
                <Card className="card-success card-outline">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <div className="my-0">
                            <Button variant={id ? 'success': 'primary'} type="submit"><FontAwesomeIcon icon={faSave} /> {id ? 'Salvar': 'Criar' }</Button>
                            <Link to="/empresa" className="btn btn-secondary ms-1"> <FontAwesomeIcon icon={faArrowLeft} /> Voltar</Link>
                        </div>
                        <div className="h5 py-0 m-0 text-muted">{id ? 'Editar' : 'Nova'} Empresa</div>
                    </Card.Header>

                    <Card.Body>
                            <Row className="g-2">
                          
                                <Col md>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Nome/Razão Social</Form.Label>
                                        <Form.Control maxLength={60} name="name" value={data.name} 
                                            type="text" onChange={handleChange}  />

                                        {errors.name  && (
                                            <ul className="errorfield">
                                                {errors.name.map((error, index) => (
                                                    <li key={index} className="text-danger">{error}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-3" controlId="cpfcnpj">
                                        <Form.Label>Nome Fantasia</Form.Label>
                                        <Form.Control maxLength={60} name="xfant" value={data.xfant} 
                                            type="text" onChange={handleChange}  />
                                            {errors.xfant && (
                                                <ul className="errorfield">
                                                    {errors.xfant.map((error, index) => (
                                                        <li key={index} className="text-danger">{error}</li>
                                                    ))}
                                                </ul>
                                            )}
                                    </Form.Group>
                                </Col>

                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="cpfcnpj">
                                        <Form.Label>CPF/CNPJ</Form.Label>
                                        <Form.Control maxLength={14} name="cpfcnpj" value={data.cpfcnpj} 
                                            type="text" onChange={handleChange} onKeyUp={handleNumberOnly} ref={cpfcnpjRef} />
                                            {errors.cpfcnpj && (
                                                <ul className="errorfield">
                                                    {errors.cpfcnpj.map((error, index) => (
                                                        <li key={index} className="text-danger">{error}</li>
                                                    ))}
                                                </ul>
                                            )}
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="ie">
                                        <Form.Label>Inscrição Estadual</Form.Label>
                                        <Form.Control maxLength={14} name="ie" value={data.ie} 
                                            type="text" onChange={handleChange} onKeyUp={handleNumberOnly} />
                                            {errors.ie && (
                                                <ul className="errorfield">
                                                    {errors.ie.map((error, index) => (
                                                        <li key={index} className="text-danger">{error}</li>
                                                    ))}
                                                </ul>
                                            )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            
                            <Row className="g-2">
                                <Col md>
                                    <Form.Group className="mb-3" controlId="xlgr">
                                        <Form.Label>Logradouro</Form.Label>
                                        <Form.Control maxLength={60} name="xlgr" value={data.xlgr} 
                                            type="text" onChange={handleChange}  />
                                            {errors.xlgr && (
                                                <ul className="errorfield">
                                                    {errors.xlgr.map((error, index) => (
                                                        <li key={index} className="text-danger">{error}</li>
                                                    ))}
                                                </ul>
                                            )}
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="nro">
                                        <Form.Label>Número</Form.Label>
                                        <Form.Control maxLength={10} name="nro" value={data.nro} 
                                            type="text" onChange={handleChange}  />
                                            {errors.nro && (
                                                <ul className="errorfield">
                                                    {errors.nro.map((error, index) => (
                                                        <li key={index} className="text-danger">{error}</li>
                                                    ))}
                                                </ul>
                                            )}
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="cep">
                                        <Form.Label>CEP</Form.Label>
                                        <Form.Control maxLength={8} name="cep" value={data.cep} 
                                            type="text" onChange={handleChange} onKeyUp={handleNumberOnly} />
                                            {errors.cep && (
                                                <ul className="errorfield">
                                                    {errors.cep.map((error, index) => (
                                                        <li key={index} className="text-danger">{error}</li>
                                                    ))}
                                                </ul>
                                            )}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="g-2">
                                <Col md>
                                    <Form.Group className="mb-3" controlId="xcpl">
                                        <Form.Label>Complemento</Form.Label>
                                        <Form.Control maxLength={60} name="xcpl" value={data.xcpl} 
                                            type="text" onChange={handleChange}  />
                                            {errors.xcpl && (
                                                <ul className="errorfield">
                                                    {errors.xcpl.map((error, index) => (
                                                        <li key={index} className="text-danger">{error}</li>
                                                    ))}
                                                </ul>
                                            )}
                                    </Form.Group>
                                </Col>
                                
                                <Col md>
                                    <Form.Group className="mb-3" controlId="xbairro">
                                        <Form.Label>Bairro</Form.Label>
                                        <Form.Control maxLength={60} name="xbairro" value={data.xbairro} 
                                            type="text" onChange={handleChange}  />
                                            {errors.xbairro && (
                                                <ul className="errorfield">
                                                    {errors.xbairro.map((error, index) => (
                                                        <li key={index} className="text-danger">{error}</li>
                                                    ))}
                                                </ul>
                                            )}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="g-2">
                                <Col md={1}>
                                    <Form.Group className="mb-3" controlId="estado_id">
                                            <Form.Label>Estado</Form.Label>
                                            <Form.Select name="estado_id" value={data.estado_id} 
                                                onChange={handleChangeEstado}>
                                                {estados.map((estado) => (
                                                    <option key={estado.id} value={estado.id}>{estado.uf}</option>
                                                ))}
                                            </Form.Select>  


                                            {errors.estado_id && (
                                                <ul className="errorfield">
                                                    {errors.estado_id.map((error, index) => (
                                                        <li key={index} className="text-danger">{error}</li>
                                                    ))}
                                                </ul>
                                            )}
                                    </Form.Group>
                                </Col>
                                <Col md>
                                    <Form.Group className="mb-3" controlId="municipio_id">
                                        <Form.Label>Município</Form.Label>
                                        <Form.Select name="municipio_id" value={data.municipio_id}  ref={municipioRef}

                                            onChange={handleChange}>
                                                
                                                {municipios && (
                                                    municipios?.map((municipio) => {
                                                        if (!id) {
                                                            return <option selected={municipio.capital === 1} key={municipio.id} value={municipio.id}>{municipio.name}</option>
                                                        } else {
                                                            return <option selected={data.municipio_id === municipio.id} key={municipio.id} value={municipio.id}>{municipio.name}</option>
                                                        }
                                                    })
                                                )}
                                            </Form.Select>
                                            {errors.municipio_id && (
                                                <ul className="errorfield">
                                                    {errors.municipio_id.map((error, index) => (
                                                        <li key={index} className="text-danger">{error}</li>
                                                    ))}
                                                </ul>
                                            )}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="g-2">
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="fone">
                                        <Form.Label>Telefone</Form.Label>
                                        <Form.Control maxLength={11} name="fone" value={data.fone} 
                                            type="text" onChange={handleChange}  />
                                            {errors.fone && (
                                                <ul className="errorfield">
                                                    {errors.fone.map((error, index) => (
                                                        <li key={index} className="text-danger">{error}</li>
                                                    ))}
                                                </ul>
                                            )}
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="celular">
                                        <Form.Label>Celular</Form.Label>
                                        <Form.Control maxLength={11} name="celular" value={data.celular} 
                                            type="text" onChange={handleChange}  />
                                            {errors.celular && (
                                                <ul className="errorfield">
                                                    {errors.celular.map((error, index) => (
                                                        <li key={index} className="text-danger">{error}</li>
                                                    ))}
                                                </ul>
                                            )}
                                    </Form.Group>
                                </Col>
                                <Col md>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control maxLength={60} name="email" value={data.email} 
                                            type="text" onChange={handleChange}  />
                                            {errors.email && (
                                                <ul className="errorfield">
                                                    {errors.email.map((error, index) => (
                                                        <li key={index} className="text-danger">{error}</li>
                                                    ))}
                                                </ul>
                                            )}
                                    </Form.Group>
                                </Col>
                                <Col md={'auto'}>
                                    <Form.Group className="mb-3" controlId="tpamb">
                                        <Form.Label>Tipo Ambiente</Form.Label>
                                        <Form.Select name="tpamb" value={data.tpamb} onChange={handleChange}>
                                            <option value={1}>Produção</option>
                                            <option value={2}>Homologação</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="g-2">
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="certificado">
                                        <Form.Label>Certificado</Form.Label>
                                        <Form.Control  maxLength={60} name="certificado" value={data.certificado} 
                                            type="file" onChange={handleChange}  />
                                    </Form.Group>
                                </Col>
                                
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="imagem">
                                        <Form.Label>Imagem</Form.Label>
                                        <Form.Control maxLength={60} name="imagem" value={data.imagem} 
                                            type="file" onChange={handleChange}  />
                                    </Form.Group>
                                </Col>
                            </Row>
                            
                    </Card.Body>
                </Card>
            </Form>
        </div>
    );
}
