import { Button, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store";
import { 
    setManifesto, setEstados, setSelectMunicipios, 
    gravarErrors, clearManifesto, loadManifesto,
    setSelectMunicipiosCarregamento, clearNFe, clearCTe,
    clearError, loadMunicipiosDescargaSelect
} from "../../features/manifestoSlice";
import auxService from "../../services/AuxService";
import MunicipioCarrega from "./MunicipioCarrega";
import UfPercurso from "./UfPercurso";
import manifestoService from "../../services/ManifestoService";
import {  JAlertaErrorFormulario, MakeMessageError } from "../../components/Components";
import { useNavigate, useParams } from "react-router-dom";
import Totalizador from "./Totalizador";
import AutorizacaoDownload from "./AutorizacaoDownload";
import ProdutoPredominante from "./ProdutoPredominante";
import { tratarErrors } from "../../utils/utils";
import InfoManifesto from "./InfoManifesto";
import LacresManifesto from "./LacresManifesto";
import InfDocumentos from "./InfDocumentos";
import Rodoviario from "./Rodoviario";


export default function FormManifesto() {

    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('principal');
    const formManifesto = useSelector((state: RootState) => state.manifesto.manifesto );
    const errors = useSelector((state: RootState) => state.manifesto.listaErrors);
    const selectEstados = useSelector((state: RootState) => state.manifesto.selectEstados);
    const listaUfPercurso = useSelector((state: RootState) => state.manifesto.manifesto.infPercurso);
    const listaMunicipiosCarregamento = useSelector((state: RootState) => state.manifesto.manifesto.infMunCarrega);
    const listaMunicipiosDescarregamento = useSelector((state: RootState) => state.manifesto.manifesto.infMunDescarga);
    const estadoInicialRef = useRef<HTMLSelectElement>(null);
    const [showProdutoPredominante, setShowProdutoPredominante] = useState(false);
    const [showDocumentoFiscal, setShowDocumentoFiscal] = useState(false);
    const [modal, setModal] = useState('1');
    const [showError, setShowError] = useState(false);
    const [showMessageError, setShowMessageError] = useState('');
    
    const navigate = useNavigate();
    const { id } = useParams<{id: string}>();

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        dispatch(setManifesto({[name]: value}));
        dispatch(clearError(name))
        
        
        if (name === 'tpemit' ) {
            if (value === '1') {
                setShowProdutoPredominante(true);
                setShowDocumentoFiscal(true);
                dispatch(clearNFe());

            } else {
                setShowProdutoPredominante(false);
                setShowDocumentoFiscal(false);
                dispatch(clearCTe());
            }
        }
        if (name==='modal') {
           setModal(value);
        }
    }

    const handleChangeEstadoInicial = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        dispatch(setManifesto({[name]: value}));

        if (value !== 'Selecione') { 
            auxService.getMunicipiosByUf(value).then(data => {
                dispatch(setSelectMunicipios(data));
            })
            .catch(error => console.error(error));
        } else {
            dispatch(setSelectMunicipios([
                {
                    id: 0,
                    name: 'Selecione uma UF Inicial',
                    estado: {
                        uf: ''
                    },
                    capital: 0,
                    cod_ibge: 0,
                }
            ]))
        }
    }

    const handleChangeEstadoFinal = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        dispatch(setManifesto({[name]: value}));
        auxService.getMunicipiosByUf(value).then(res => {
            dispatch(setSelectMunicipiosCarregamento(res));
        })
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        await manifestoService.create(formManifesto).then(res => {
            dispatch(clearManifesto());
            navigate('/manifesto');
        })
        .catch(err => {
          
            const listaErrors = ['prodPred', 'modal_rodoviario'];
            const newerrors = tratarErrors(err.response.data, listaErrors);
            dispatch(gravarErrors({...newerrors}))
            
            // const fields = Object.keys(err.response.data)
            // console.log('fields', fields)
            // if (fields.length>0) {
            //     setShowMessageError(err.response.data[fields[0]][0]);
            // }
            setShowMessageError('Houve erro ao gravar. Verifique o formulário.');

            setShowError(true)
        })
        
    }

    const handleCancelar = () => {
        dispatch(clearManifesto());
        navigate('/manifesto');
    }

    useEffect(() => {

        auxService.getEstados().then((estados) => {
            dispatch(setEstados(estados));
        }
        ).catch((error) => {
            console.error(error);
        });


        if (!id) {
            if (formManifesto.ufini !== "Selecione") {
                auxService.getMunicipiosByUf(formManifesto.ufini).then(data => {
                    dispatch(setSelectMunicipios(data));
                })
                .catch(error => console.error(error));
            }
            
            if (formManifesto.uffim !== "Selecione") {
                auxService.getMunicipiosByUf(formManifesto.uffim).then(data => {
                    dispatch(setSelectMunicipiosCarregamento(data));
                })
                .catch(error => console.error(error));
            }
            
            
        } else {
            manifestoService.get(id).then(data => {
                dispatch(loadManifesto(data));
                    
                    auxService.getMunicipiosByUf(data.ufini).then(data => {
                        dispatch(setSelectMunicipios(data));
                    })
                    .catch(error => console.error(error));
                   
                
                    auxService.getMunicipiosByUf(data.uffim).then(data => {
                        dispatch(setSelectMunicipiosCarregamento(data));
                    })
                    .catch(error => console.error(error));
                    dispatch(loadMunicipiosDescargaSelect())
            })
            .catch(error => console.error)
            
           
        }

    }, [])

    const disabledUFFinal = () => {
        return listaUfPercurso.length > 0 || listaMunicipiosDescarregamento.length>0
    }
    const disabledUFInicial = () => {
        return listaMunicipiosCarregamento.length>0
    }

    return (
        <div>
            
            <Form onSubmit={handleSubmit}>
                <Card className="card-success card-outline">
                    <Card.Header className="d-flex justify-content-between">
                        <div className="row">
                            <Col md>
                            
                                <Button type="submit" className="me-2" ><FontAwesomeIcon icon={faSave} /> Salvar</Button>
                                <a href="javasctipt:void(0)" onClick={handleCancelar} className="btn btn-danger"><FontAwesomeIcon icon={faTimes} /> Cancelar</a>
                            </Col>
                            <Col md={'auto'} className="ms-auto" >
                                <JAlertaErrorFormulario showError={showError} close={setShowError} messageError={showMessageError} variant="danger" /> 
                            </Col>
                        </div>
                        {/* <div className="mb-1 g-2">
                            <Button type="submit" className="me-2" ><FontAwesomeIcon icon={faSave} /> Salvar</Button>
                            <a href="javasctipt:void(0)" onClick={handleCancelar} className="btn btn-danger"><FontAwesomeIcon icon={faTimes} /> Cancelar</a>
                        </div>

                        <div>
                            <JAlertaErrorFormulario showError={showError} close={setShowError} messageError={showMessageError} variant="danger" /> 
                        </div> */}
                    </Card.Header>

                    <Card.Body className="p-2">
                        <Tabs defaultActiveKey="principal" activeKey={activeTab} onSelect={(k) => setActiveTab(k || 'principal')} id="tab-example" className="mb-3">
                            <Tab eventKey="principal" title="Principal" className="px-1">
                                <Card className="card-primary card-outline mb-3">
                                    <Card.Body>
                                        <Row className="mb-3 g-2">
                                            <Col md={'auto'}>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>Número</Form.Label>
                                                    <Form.Control type="text" name="nmdf" value={formManifesto.nmdf} onChange={handleChange} autoFocus/>
                                                    <MakeMessageError errors={errors} field="nmdf"/>
                                                  
                                                </Form.Group>
                                            </Col>

                                            <Col md>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>UF Inicial</Form.Label>
                                                    <Form.Select name="ufini"  value={formManifesto.ufini} onChange={handleChangeEstadoInicial} ref={estadoInicialRef} disabled={disabledUFInicial()}>
                                                        {selectEstados?.map((estado) => {
                                                            return <option key={estado.id} value={estado.uf}>{estado.uf}</option>
                                                        })}
                                                    </Form.Select>
                                                    <MakeMessageError errors={errors} field="ufini"/>
                                                </Form.Group>
                                            </Col>

                                            <Col md={'auto'}>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>Tipo Emitente</Form.Label>
                                                    <Form.Select name="tpemit"  value={formManifesto.tpemit} onChange={handleChange} >
                                                        <option key="2" value="2">2-Transportador de Carga Própria</option>
                                                        <option key="1" value="1">1-Prestador de Serviço de Transporte</option>
                                                    </Form.Select>
                                                    <MakeMessageError errors={errors} field="tpemit"/>
                                                </Form.Group>
                                            </Col>

                                            <Col md>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>Tipo Transportador</Form.Label>
                                                    <Form.Select name="tptransp" value={formManifesto.tptransp} onChange={handleChange} >
                                                        <option key="0" value="0">0-Não informado</option>
                                                        <option key="1" value="1">1-ETC</option>
                                                        <option key="2" value="2">2-TAC</option>
                                                        <option key="3" value="3">3-CTC</option>
                                                    </Form.Select>
                                                    <MakeMessageError errors={errors} field="tptransp"/>
                                                </Form.Group>
                                            </Col>

                                            <Col md>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>Modal</Form.Label>
                                                    <Form.Select name="modal" value={formManifesto.modal} onChange={handleChange} >
                                                        <option key="1" value="1">1-Rodoviário</option>
                                                        <option key="2" value="2">2-Aéreo</option>
                                                        <option key="4" value="4">4-Aquaviário</option>
                                                        <option key="5" value="5">5-Ferroviário</option>
                                                        
                                                    </Form.Select>
                                                    <MakeMessageError errors={errors} field="modal"/>
                                                </Form.Group>
                                            </Col>

                                            <Col md>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>UF Final</Form.Label>
                                                    <Form.Select name="uffim"  value={formManifesto.uffim} onChange={handleChangeEstadoFinal} disabled={disabledUFFinal()}>
                                                        {selectEstados?.map((estado) => {
                                                            return <option key={estado.id} value={estado.uf}>{estado.uf}</option>
                                                        })}
                                                    </Form.Select>
                                                    <MakeMessageError errors={errors} field="uffim"/>
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        {/* <FormTeste/> */}
                                    </Card.Body>
                                </Card>
                                <Row className="mb-3 g-2">
                                    <Col md>
                                        <MunicipioCarrega estadoInicialRef={estadoInicialRef}/>
                                    </Col>
                                    <Col md>
                                        <UfPercurso  />
                                    </Col>
                                </Row>
                            </Tab>

                            {modal === '1' && (
                                <Tab eventKey="rodoviario" title="Rodoviário">
                                    <Rodoviario/>
                                </Tab>
                            )}

                            {modal === '2' && (
                                <Tab eventKey="aereo" title="Aéreo">
                                    <h6>Aéreo</h6>
                                </Tab>
                            )}

                            {modal === '4' && (
                                <Tab eventKey="aquatico" title="Aquático">
                                    <h6>Aquático</h6>
                                </Tab>
                            )}

                            {modal === '5' && (
                                <Tab eventKey="ferroviario" title="Ferroviario">
                                    <h6>Ferroviario</h6>
                                </Tab>
                            )}
                         
                            <Tab eventKey="inf_documentos" title="Inf. Documentos">
                                <InfDocumentos showDocumentoFiscal={showDocumentoFiscal} />
                            </Tab>

                            <Tab eventKey="inf_seguro" title="Inf. Seguros">
                               
                            </Tab>
                           

                            <Tab eventKey="totalizadores" title="Totalizadores">
                                <Totalizador handleChange={handleChange} errors={errors} /> 
                            </Tab>

                            <Tab eventKey="inf_adicionais" title="Informações Adicionais">
                                <InfoManifesto  handleChange={handleChange}/>
                            </Tab>


                            <Tab eventKey="manifesto_lacre" title="Lacres do manifesto">
                                <LacresManifesto />
                            </Tab>

                            <Tab eventKey="autorizacao_download" title="Autorização Download">
                                <AutorizacaoDownload/>
                            </Tab>

                            {showProdutoPredominante && (
                                <Tab eventKey="produto_predominante" title="Produto Predominante">
                                    <ProdutoPredominante />  
                                </Tab>

                            )}
                        </Tabs>

                      

                    </Card.Body>

                </Card>

            </Form>
            
        </div>
    )
}