import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { addListaUFPercurso, removeListaUFPercurso } from "../../features/manifestoSlice";
import { useRef } from "react";

export default function UfPercurso() {
    const dispatch = useDispatch();
    const listaUfPercurso = useSelector((state: RootState) => state.manifesto.manifesto.infPercurso);
    const selectUfPercurso = useSelector((state: RootState) => state.manifesto.selectEstados);
    const form = useSelector((state: RootState) => state.manifesto.manifesto);
    const ufPercursoRef = useRef<HTMLSelectElement>(null);
        
    const handleAddUfPercurso = () => {
        const idUf = ufPercursoRef.current?.value;
        const txtuf = ufPercursoRef.current?.options[ufPercursoRef.current?.selectedIndex].text ?? '';

        if (idUf === '0') {
            alert('Nenhum estado selecionado.');
            return;
        }

        if (txtuf === form.ufini ||  txtuf === form.uffim) 
        {
            alert('UF de percurso não pode ser igual a UF Inicial ou UF Final.');
            return;
        }
        
        if (form.infPercurso.find((item) => item.uf === txtuf)) {
            alert('UF já adicionada');
            return;
        }

        if (form.infPercurso.length === 25) {
            alert('Limite máximo de 25 UFs de percurso atingido.');
            return;
        }

        if (form.infMunCarrega.length === 0) {
            alert('Informe primeiramente o município de carregamento.');
            return;
        }


        if (idUf) {
           dispatch(addListaUFPercurso({
                id: parseInt(idUf),
                uf: txtuf
           }))
        }
    }

    const handleRemoveUfPercurso = (id: number) => {
        if (window.confirm('Deseja realmente remover?')) {
            dispatch(removeListaUFPercurso(id));
        }
    }

    return (
        <Card className="card-primary card-outline">
            <div className="p-3">
                <h6 className="">UFs de percurso <span className="text-dark">(25 no máximo)</span></h6>
                <Row className="g-2 pt-2">
                    <Col md={8}>
                        <Form.Group className="mb-2">
                            <Form.Select  name="uf_percurso" id="uf_percurso" ref={ufPercursoRef}>
                                {selectUfPercurso.map((estado) => (
                                    <option key={estado.id} value={estado.id}>{estado.uf}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={'auto'}>
                        <Form.Group className="mb-2">
                            <Button variant="primary" onClick={handleAddUfPercurso}> <FontAwesomeIcon icon={faPlus} /> Adicionar</Button>
                        </Form.Group>
                    </Col>
                    
                </Row>
            </div>
            <Card.Body className="p-3">
                <Row>
                    <Col md>
                        <Table striped bordered hover size="sm" responsive="sm">
                            <thead className="table-light">
                                <tr>
                                    <th className="text-center" style={{width: '2.5rem'}}>#</th>
                                    <th>UF</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listaUfPercurso.map((estado) => (
                                    <tr key={estado.estado_id}>
                                        
                                        <td className="text-center">
                                            <a href="#" className="text-danger" onClick={() => handleRemoveUfPercurso(estado.estado_id)}>
                                                <i className="fas fa-trash"></i>
                                            </a>
                                        </td>
                                        <td>
                                            {estado.uf}
                                        </td>
                                        
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}