import api from "../api/api";

class MunicipioService {
    async getAllPaginate(search: string, page: string | null) {
        let q = '';
        if (search?.length > 0) {
            q = `?q=${search}`;
            q += page ? `&page=${page}` : "";
            return await api.get(`/municipio${q}`).then(r => r.data);
        } else {
            if (page) {
                return await api.get(`/municipio?page=${page}`).then(r => r.data);
            } else {
                return await api.get(`/municipio`).then(r => r.data);
            }
        }
    }

    async get(id: string) {
        return await api.get(`/municipio/${id}`).then(response => response.data);
    }

    async getMunicipiosPorEstado(estado: string) {
        let query = "";
        if (estado !== "") {
            query = `?param_estado=${estado}`;
        }
        return await api.get(`/municipio/list-municipios/${query}`).then(response => response.data);
    }

    async create(data: any) {
        return await api.post("/municipio", data).then(response => response.data);
    }

    async update(id: string, data: any) {
        return await api.put(`/municipio/${id}`, data).then(response => response.data);
    }

    async delete(id: string) {
        return await api.delete(`/municipio/${id}`).then(response => response.data);
    }
}

const municipioService = new MunicipioService();

export default municipioService;