import api from "../api/api";

class ManifestoService {
    async getAllPaginate(search: string, page: string | null) {
        let q = '';
        if (search?.length > 0) {
            q = `?q=${search}`;
            q += page ? `&page=${page}` : "";
            return await api.get(`/manifesto${q}`).then(r => r.data);
        } else {
            if (page) {
                return await api.get(`/manifesto?page=${page}`).then(r => r.data);
            } else {
     
                 return await api.get(`manifesto`).then(r => r.data);
            }
        }
    }

    async get(id: string) {
        return await api.get(`/manifesto/${id}`).then(response => response.data);
    }

    async create(data: any) {
        return await api.post("/manifesto", data).then(response => response.data);
    }

    async update(id: string, data: any) {
        return await api.put(`/manifesto/${id}`, data).then(response => response.data);
    }

    async delete(id: string) {
        return await api.delete(`/manifesto/${id}`).then(response => response.data);
    }
}

const manifestoService = new ManifestoService();

export default manifestoService;