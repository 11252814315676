import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import Antt from "./Antt";
import VeicTracao from "./VeicTracao";
import LacresRodoviario from "./LacresRodoviario";
import Reboques from "./Reboques";

export default function Rodoviario() {
    return (
        <Tabs defaultActiveKey="rodo_veictracao"  id="tab-rodoviario" className="mb-3">
            <Tab eventKey="rodo_veictracao" title="Veículo de Tração">
                <VeicTracao/>
            </Tab>

            <Tab eventKey="rodo_reboque" title="Reboques">
                <Reboques/>
            </Tab>

            <Tab eventKey="rodo_antt" title="ANTT">
                <Antt/>
            </Tab>

            <Tab eventKey="rodo_lacre" title="Lacres">
                <LacresRodoviario/>
            </Tab>
        </Tabs>
    

    )
}