import { Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeTracaoManifesto, changeTracaoProp, clearError, clearTracaoProp } from "../../features/manifestoSlice";
import { handleNumberOnly } from "../../utils/utils";
import { RootState } from "../../app/store";
import { MakeMessageError } from "../../components/Components";
import { useState } from "react";


export default function Veiculo() {
    const selectEstados = useSelector((state: RootState) => state.manifesto.selectEstados);
    const form = useSelector((state: RootState) => state.manifesto.manifesto.modal_rodoviario?.tracao);
    const formProp = useSelector((state: RootState) => state.manifesto.manifesto.modal_rodoviario?.tracao.prop);
    const errors = useSelector((state: RootState) => state.manifesto.listaErrors);
    const [showProp, setShowProp] = useState(false);
    const dispatch = useDispatch();
  
        const handleChange = (e: any) => {
            const {name, value} = e.target;
            dispatch(changeTracaoManifesto({[name]: value}));
            dispatch(clearError('modal_rodoviario_tracao_'+name))
        }

        const handleChangeShowProp = (e: any) => {
            setShowProp(e.target.checked)
            if (!e.target.checked) {
                dispatch(clearTracaoProp());
            }
        }

        const handleChangeProp = (e: any) => {
            const {name, value} = e.target;
            dispatch(changeTracaoProp({[name]: value}));
            dispatch(clearError('modal_rodoviario_tracao_prop_'+name))
        }


    return (
        <Card className="card-primary card-outline mb-3">
            <Card.Body>

                <Row className="g-2 mb-3">
                    <Col md>
                        <Form.Group>
                            <Form.Label>Código do veículo</Form.Label>
                            <Form.Control name="cint" type="text"  value={form.cint} onKeyUp={handleNumberOnly} onChange={handleChange}/>
                        </Form.Group>
                    </Col>

                    <Col md>
                        <Form.Group>
                            <Form.Label>Tipo Carroceria</Form.Label>
                            <Form.Select name="tpCar" value={form.tpCar}  onChange={handleChange}>
                                <option value="10">0 - Não Aplicável</option>
                                <option value="0">0 - Não Aplicável</option>
                                <option value="1">1 - Aberta</option>
                                <option value="2">2 - Fechada/Baú</option>
                                <option value="3">3 - Granelera</option>
                                <option value="4">4 - Porta Container</option>
                                <option value="5">5 - Sider</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col md={'auto'}>
                        <Form.Group>
                            <Form.Label>*Placa</Form.Label>
                            <Form.Control name="placa" value={form.placa} type="text"  onChange={handleChange} />
                            <MakeMessageError errors={errors} field="modal_rodoviario_tracao_placa"/>
                        </Form.Group>
                    </Col>
                    
                </Row>

                <Row className="g-2 mb-3">
                    <Col md>
                        <Form.Group>
                            <Form.Label>*Tara(kg)</Form.Label>
                            <Form.Control name="tara" type="text" value={form.tara} onKeyUp={handleNumberOnly} onChange={handleChange} />
                            <MakeMessageError errors={errors} field="modal_rodoviario_tracao_tara"/>
                        </Form.Group>
                    </Col>

                    <Col md>
                        <Form.Group>
                            <Form.Label>RENAVAM</Form.Label>
                            <Form.Control name="RENAVAM" type="text"  onChange={handleChange}/>
                        </Form.Group>
                    </Col>

                    <Col md>
                        <Form.Group>
                            <Form.Label>*Tipo Rodado</Form.Label>
                            <Form.Select name="tpRod" onChange={handleChange} value={form.tpRod}>
                            <option value="10">10 - Truck</option>
                            <option value="1">1 - Truck</option>
                            <option value="2">2 - Toco</option>
                            <option value="3">3 - Cavalo Mecanico</option>
                            <option value="4">4 - VAN</option>
                            <option value="5">5 - Utilitario</option>
                            <option value="6">6 - Outros</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col md>
                        <Form.Group>
                            <Form.Label>Capacidade(kg)</Form.Label>
                            <Form.Control name="capKG" type="text" onKeyUp={handleNumberOnly} value={form.capKG} onChange={handleChange}/>
                        </Form.Group>
                    </Col>

                    <Col md>
                        <Form.Group>
                            <Form.Label>Capacidade(M³)</Form.Label>
                            <Form.Control name="capM3" type="text" onKeyUp={handleNumberOnly} value={form.capM3} onChange={handleChange}/>
                        </Form.Group>
                    </Col>

                    <Col md={'auto'}>
                        <Form.Group>
                            <Form.Label>UF</Form.Label>
                            <Form.Select name="uf" onChange={handleChange} value={form.uf}>
                            {selectEstados?.map((estado) => {
                                return <option key={estado.id} value={estado.uf}>{estado.uf}</option>
                            })}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    
                </Row>
                
                <Row className="g-2 mb-3">
                    <Col md className="">
                        <Form.Group className="" controlId="active">
                            <Form.Switch type="switch" onChange={handleChangeShowProp} checked={showProp}
                                name="active" label="O proprietário não é emitente iniciado no aplicativo?" />
                        </Form.Group>
                    </Col>

                </Row>
                {showProp && (
                    <>
                        <Row className="g-2 mb-3">
                            <Col md>
                                <Form.Group>
                                    <Form.Label>RNTRC</Form.Label>
                                    <Form.Control type="text" name="rntrc" onChange={handleChangeProp} value={formProp.rntrc} />
                                    <MakeMessageError errors={errors} field="modal_rodoviario_tracao_prop_rntrc"/>
                                </Form.Group>
                            </Col>

                            <Col md>
                                <Form.Group>
                                    <Form.Label>Tipo Proprietário</Form.Label>
                                    <Form.Select name="tpProp" value={formProp.tpProp} onChange={handleChangeProp}>
                                        <option value="">Selecione</option>
                                        <option value="0">0 - TAC Agregado</option>
                                        <option value="1">1 - TAC Independente</option>
                                        <option value="2">2 - Outros</option>
                                    </Form.Select>
                                    <MakeMessageError errors={errors} field="modal_rodoviario_tracao_prop_tpProp"/>
                                </Form.Group>
                            </Col>

                            <Col md={'auto'}>
                                <Form.Group>
                                    <Form.Label>UF</Form.Label>
                                    <Form.Select name="uf" value={formProp.uf} onChange={handleChangeProp}>
                                    {selectEstados?.map((estado) => {
                                        return <option key={estado.id} value={estado.uf}>{estado.uf}</option>
                                    })}
                                        
                                    </Form.Select>
                                    <MakeMessageError errors={errors} field="modal_rodoviario_tracao_prop_uf"/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="g-2 mb-3">
                            <Col md>
                                <Form.Group>
                                    <Form.Label>*Nome do proprietário</Form.Label>
                                    <Form.Control type="text" name="xNome" value={formProp.xNome} onChange={handleChangeProp} />
                                    <MakeMessageError errors={errors} field="modal_rodoviario_tracao_prop_xNome"/>
                                </Form.Group>
                            </Col>

                            <Col md={2}>
                                <Form.Group>
                                    <Form.Label>*CPF/CNPJ</Form.Label>
                                    <Form.Control type="text" name="cpfcnpj" value={formProp.cpfcnpj}  onKeyUp={handleNumberOnly} onChange={handleChangeProp}/>
                                    <MakeMessageError errors={errors} field="modal_rodoviario_tracao_prop_cpfcnpj"/>
                                </Form.Group>
                            </Col>

                            <Col md={2}>
                                <Form.Group>
                                    <Form.Label>Inscrição Estadual</Form.Label>
                                    <Form.Control type="text" name="ie" value={formProp.ie} onKeyUp={handleNumberOnly} onChange={handleChangeProp}/>
                                    <MakeMessageError errors={errors} field="modal_rodoviario_tracao_prop_ie"/>
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                    
                )}
            </Card.Body>
        </Card>
    )
}