import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import { EstadoInterface, MunicipioInterface } from "../../interfaces";
import auxService from "../../services/AuxService";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";


interface UsuarioForm {
    email: string;
    password: string;
    name: string;
    cpfcnpj: string;
    ie: string;
    name_empresa: string;
    name_fantasia: string;
    logradouro: string;
    numero: string;
    bairro: string;
    cep: string;
    complemento: string;
    estado_id: number;
    municipio_id: number;
    fone: string;
    celular: string;
}

const initial = {
    email: '',
    password: '',
    name: '',
    cpfcnpj: '',
    name_empresa: '',
    name_fantasia: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cep: '',
    complemento: '',
    estado_id: 0,
    municipio_id: 0,
    fone: '',
    celular: '',
    ie: ''
}

const initialFieldsError = {
    name: [],
    email: [],
    password: [],
    cpfcnpj: [],
    name_empresa: [],
    name_fantasia: [],
    logradouro: [],
    numero: [],
    cep: [],
    complemento: [],
    bairro: [],
    estado_id: [],
    municipio_id: [],
    fone: [],
    celular: [],
    ie: []

}

export default function RegisterForm() {
    const [usuarioForm, setUsuarioForm] = useState<UsuarioForm>(initial);
    const [municipios, setMunicipios] = useState<MunicipioInterface[]>([]);
    const municipioRef = useRef<HTMLSelectElement>(null);
    const [errors, setErrors] = useState(initialFieldsError);
    const navigate = useNavigate();
    
    useEffect(() => {
        auxService.getEstados().then(data => {
            setEstados([...estados, ...data])
        })
        municipioRef.current?.setAttribute('disabled', '');
    }, [])

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        
        setUsuarioForm((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const [estados, setEstados] = useState<EstadoInterface[]>([{
        id: 0, 
        uf: 'Selecione', 
        name: 'Selecione', 
        cod_ibge: 0,
        pais: { name: 'Brasil' }
        
    }]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        auxService.postRegister(usuarioForm).then(data => {
            navigate('/login')

        }).catch(err => {
            if (err.response.status === 422) {
                setErrors(err.response.data)
            }
        })
        
    };
    
    const handleChangeEstado = (e: any) => {
        const {name, value} = e.target;
        municipioRef.current?.setAttribute('disabled', '');
        

        if (value > 0) {
            auxService.getMunicipios(value).then(data => {
                setMunicipios(data)
                setUsuarioForm((prev) => ({
                    ...prev,
                    municipio_id: data.filter((municipio: MunicipioInterface) => municipio.capital === 1)[0]?.id || 0
                }))
            municipioRef.current?.removeAttribute('disabled');
        }).catch(err => {
                municipioRef.current?.removeAttribute('disabled');
            })
        } else {
            municipioRef.current?.setAttribute('disabled', '');
            
            setMunicipios([])
            setUsuarioForm((prev) => ({
                ...prev,
                municipio_id: 0
            }))
        }

        setUsuarioForm((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    return (
        <div className="container vh-100 d-flex justify-content-center align-items-center">
             <div className="col">
            
                <Form onSubmit={handleSubmit} noValidate>
                    <Card className="card-success card-outline">
                    <Card.Header>
                            <h4 className="text-center">Registro</h4>
                        </Card.Header>
                        <Card.Body>
                            
                            <Row className="g-2">
                                <Col md>
                                    <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Nome do usuário:</Form.Label>
                                    <Form.Control type="text" name="name"  
                                        value={usuarioForm.name} 
                                        required
                                        maxLength={60}
                                        onChange={handleChange} autoFocus/>
                                         {errors.name  && (
                                            <ul className="errorfield">
                                               <li className="text-danger">{errors.name[0]}</li>
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md>
                                    <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>E-mail:</Form.Label>
                                    <Form.Control type="email" name="email" 
                                        value={usuarioForm.email} 
                                        required
                                        maxLength={60}
                                        onChange={handleChange}/>                                        
                                        {errors.email  && (
                                            <ul className="errorfield">
                                               <li className="text-danger">{errors.email[0]}</li>
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-3" controlId="password">
                                    <Form.Label>Senha:</Form.Label>
                                    <Form.Control type="password" name="password" 
                                        value={usuarioForm.password} 
                                        required
                                        maxLength={60}
                                        onChange={handleChange}/>
                                        {errors.password  && (
                                            <ul className="errorfield">
                                               <li className="text-danger">{errors.password[0]}</li>
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="g-2">
                                <Col md>
                                    <Form.Group className="mb-3" controlId="name_empresa  ">
                                    <Form.Label>Nome / Razão Social:</Form.Label>
                                    <Form.Control type="text" name="name_empresa" 
                                        value={usuarioForm.name_empresa} 
                                        required
                                        maxLength={60}
                                        onChange={handleChange}/>
                                        {errors.name_empresa  && (
                                            <ul className="errorfield">
                                               <li className="text-danger">{errors.name_empresa[0]}</li>
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
        
                                <Col md={3}>
                                    <Form.Group className="mb-3" controlId="name_fantasia  ">
                                    <Form.Label>Nome fantasia:</Form.Label>
                                    <Form.Control type="text" name="name_fantasia" 
                                        value={usuarioForm.name_fantasia} 
                                        required
                                        maxLength={60}
                                        onChange={handleChange}/>
                                        {errors.name_fantasia  && (
                                            <ul className="errorfield">
                                               <li className="text-danger">{errors.name_fantasia[0]}</li>
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
                                
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="cpfcnpj  ">
                                    <Form.Label>CPF/CNPJ:</Form.Label>
                                    <Form.Control type="text" name="cpfcnpj" 
                                        value={usuarioForm.cpfcnpj} 
                                        required
                                        maxLength={14}
                                        onChange={handleChange}/>
                                        {errors.cpfcnpj  && (
                                            <ul className="errorfield">
                                                    <li className="text-danger">{errors.cpfcnpj[0]}</li>
                                                
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="ie">
                                    <Form.Label>Inscrição Estadual:</Form.Label>
                                    <Form.Control type="text" name="ie" 
                                        value={usuarioForm.ie} 
                                        maxLength={14}
                                        onChange={handleChange}/>
                                        {errors.ie  && (
                                            <ul className="errorfield">
                                               <li className="text-danger">{errors.ie[0]}</li>
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
        
                            <Row className="g-2">
                                <Col md>
                                    <Form.Group className="mb-3" controlId="logradouro">
                                    <Form.Label>Logradouro:</Form.Label>
                                    <Form.Control type="text" name="logradouro" 
                                        value={usuarioForm.logradouro} 
                                        required
                                        maxLength={60}
                                        onChange={handleChange}/>
                                        {errors.logradouro  && (
                                            <ul className="errorfield">
                                               <li className="text-danger">{errors.logradouro[0]}</li>
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-3" controlId="numero">
                                    <Form.Label>Número:</Form.Label>
                                    <Form.Control type="text" name="numero" 
                                        value={usuarioForm.numero} 
                                        required
                                        maxLength={60}
                                        onChange={handleChange}/>
                                        {errors.numero  && (
                                            <ul className="errorfield">
                                               <li className="text-danger">{errors.numero[0]}</li>
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="cep">
                                    <Form.Label>CEP:</Form.Label>
                                    <Form.Control type="text" name="cep" 
                                        value={usuarioForm.cep} 
                                        required maxLength={8}
                                        onChange={handleChange}/>
                                        {errors.cep  && (
                                            <ul className="errorfield">
                                               <li className="text-danger">{errors.cep[0]}</li>
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="g-2">
                                
                                <Col md>
                                    <Form.Group className="mb-3" controlId="complemento">
                                    <Form.Label>Complemento:</Form.Label>
                                    <Form.Control type="text" name="complemento" 
                                        value={usuarioForm.complemento} 
                                        required
                                        maxLength={60}
                                        onChange={handleChange}/>
                                        {errors.complemento  && (
                                            <ul className="errorfield">
                                               <li className="text-danger">{errors.complemento[0]}</li>
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
                                
                                <Col md>
                                    <Form.Group className="mb-3" controlId="bairro">
                                    <Form.Label>Bairro:</Form.Label>
                                    <Form.Control type="text" name="bairro" 
                                        value={usuarioForm.bairro} 
                                        required
                                        maxLength={60}
                                        onChange={handleChange}/>
                                        {errors.bairro  && (
                                            <ul className="errorfield">
                                               <li className="text-danger">{errors.bairro[0]}</li>
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>

                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="fone">
                                    <Form.Label>Telefone:</Form.Label>
                                    <Form.Control type="text" name="fone" maxLength={11}
                                        value={usuarioForm.fone} 
                                        
                                        onChange={handleChange}/>
                                        {errors.fone  && (
                                            <ul className="errorfield">
                                               <li className="text-danger">{errors.fone[0]}</li>
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>

                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="celular">
                                    <Form.Label>Celular:</Form.Label>
                                    <Form.Control type="text" name="celular" maxLength={11}
                                        value={usuarioForm.celular} 
                                        onChange={handleChange}/>
                                        {errors.celular  && (
                                            <ul className="errorfield">
                                               <li className="text-danger">{errors.celular[0]}</li>
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row className="g-2">
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="estado_id">
                                    <Form.Label>Estados</Form.Label>
                                    <Form.Select name="estado_id" value={usuarioForm.estado_id} 
                                        onChange={handleChangeEstado}>
                                        {estados.map((estado) => (
                                            <option key={estado.id} value={estado.id}>{estado.uf}</option>
                                        ))}
                                    </Form.Select>  
                                    {errors.estado_id  && (
                                            <ul className="errorfield">
                                               <li className="text-danger">{errors.estado_id[0]}</li>
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
        
                                <Col md>
                                    <Form.Group className="mb-3" controlId="municipio_id">
                                    <Form.Label>Municípios</Form.Label>
                                    <Form.Select name="municipio_id" value={usuarioForm.municipio_id}  ref={municipioRef}
                                        onChange={handleChange}>
                                            {municipios && (
                                                municipios?.map((municipio) => {
                                                    return <option selected={municipio.capital === 1} key={municipio.id} value={municipio.id}>{municipio.name}</option>
                                                })
                                            )}
                                        </Form.Select>
                                        {errors.municipio_id  && (
                                            <ul className="errorfield">
                                               <li className="text-danger">{errors.municipio_id[0]}</li>
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            
                        </Card.Body>
                        <Card.Footer>
                        <Row className="g-2">
                                <Col md>
                                    <Link className="btn btn-secondary" to="/login"><FontAwesomeIcon icon={faArrowLeft} /> Voltar</Link>
                                </Col>
                                <Col md>
                                    <div className="d-flex justify-content-end">
                                        <Button type="submit"> <FontAwesomeIcon icon={faSave} /> Registrar</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
        </div>
    )
}