import { Button, Card, Col, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import { 
    addListaMunicipiosDescarregamento, 
    removeListaMunicipioDescarregamento
} from "../../features/manifestoSlice";
import NFes from "./NFes";
import CTes from "./CTes";

export default function InfDocumentos (props: any) {
    const selectMunicipiosCarregamento = useSelector((state: RootState) => state.manifesto.selectMunicipiosCarregamentos);
    const munDescarga = useSelector((state: RootState) => state.manifesto.manifesto.infMunDescarga);
    const munDescargaSelectRef = useRef<HTMLSelectElement>(null);
    const dispatch = useDispatch();

    const handleAddMunicipioDescarga = () => {
        const value = munDescargaSelectRef?.current?.value || ''
        const nomeMunicipio = munDescargaSelectRef.current?.options[munDescargaSelectRef.current?.selectedIndex].text || '';
        
        if (value === '') {
            window.alert('Informe um município');
            return;
        }

        if (munDescarga.find(item => item.municipio_id === parseInt(value))) {
            window.alert('Município já adicionado.')
            return;
        }

        dispatch(addListaMunicipiosDescarregamento({municipio_id: parseInt(value), name: nomeMunicipio}))
    }

    const handleRemoveMunicipioDescarga = (id: number) => {
        if (window.confirm('Deseja realmente remover?')) {
            dispatch(removeListaMunicipioDescarregamento(id));
        }
    }
  
    return (
       
        <Tabs defaultActiveKey="tab_municipio_descarregamento"  id="tab-example" className="mb-3">
            <Tab eventKey="tab_municipio_descarregamento" title="Municípios de descarregamento">
                <Card className="card-primary card-outline mb-3">
                    <Card.Body>
                        
                        <Row className="g-2 pb-2">
                            <Col md={4}>
                                <Form.Group className="mb-2">
                            
                                    <Form.Select  name="municipio_carregamento" id="municipio_carregamento"  ref={munDescargaSelectRef} >
                                        {selectMunicipiosCarregamento?.map((municipio: any) => (
                                            <option key={municipio.id} selected={municipio.capital === 1} value={municipio.id}>{municipio.name}</option>
                                        ))}
                                    </Form.Select>
                                
                                </Form.Group>
                
                            </Col>

                            <Col md={'auto'}>
                                <Form.Group className="mb-2">
                                    <Button variant="primary" onClick={handleAddMunicipioDescarga} > <FontAwesomeIcon icon={faPlus} /> Adicionar</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                        <h6 className="pb-2"><span className="text-dark">(100 no máximo)</span></h6>
                        <Table striped bordered hover size="sm" responsive="sm">
                            <thead className="table-light">
                                <tr>
                                    <th className="text-center" style={{width: '2.5rem'}}>#</th>
                                    <th>Município</th>
                                </tr>
                            </thead>
                            <tbody>
                                {munDescarga?.map(item => {
                                    return (
                                        <tr key={item.municipio_id}>
                                            <td className="text-center">
                                                <a href="#" className="text-danger" onClick={() => handleRemoveMunicipioDescarga(item.municipio_id)}>
                                                    <i className="fas fa-trash"></i>
                                                </a>
                                            </td>
                                            <td>{item.name}</td>
                                        </tr>
                                    );
                                })}
                            
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Tab>
            
            {!props.showDocumentoFiscal && (
                <Tab eventKey="tab_md_nfe" title="Nota Fiscal Eletrônica (NF-e)" >
                    <NFes />
                </Tab>
            )}
            
            {props.showDocumentoFiscal && (
                <Tab eventKey="tab_md_cfe" title="Conhecimento Eletrônico (CT-e)" >
                    <CTes/>
                </Tab>
            )}
            
        </Tabs>
        
    );
}


