import { useEffect, useState, useContext} from "react";
import { MunicipioInterface as iData } from "../../interfaces";
import municipioService from "../../services/MunicipioService";
import {
    JCaption, 
    JCard, 
    JCardBody, 
    JHeader, 
    JPaginacao, 
    JTable, 
    JTBody, 
    JTHead, 
} from "../../components/Components";
import { AuthContext } from "../../contexts/AuthContext";

export default function IndexMunicipio() {
    const [dados, setDados] = useState<iData[]>([]);
    const [showSpinner, setShowSpinner] = useState(true);
    const [search, setSearch] = useState('');
    const [meta, setMeta] = useState<any>(null)
    const [links, setLinks] = useState<any>(null)
    const authContext = useContext(AuthContext);
    const  searchFilter =  (page: string | null = null) =>  {
        
        setShowSpinner(true);
        
        municipioService.getAllPaginate(search, page).then( response => {
            setDados(response.data);
            setLinks(response.links);
            setMeta(response.meta);
            setShowSpinner(false);
            
        }).catch(err => {
            setShowSpinner(false);
        });
    }

    
    const handlePageChange = (page: string | null = null) => {
        searchFilter(page)
    }

    useEffect(() => {
        searchFilter();
    }, [search]);

    return (
        <>
            <JCard>
                <JHeader  
                    search={search}
                    setSearch={setSearch}
                    showSpinner={showSpinner}
                    permissionCreate={authContext?.checkPermission('municipio_create')}
                    permissionSearch={authContext?.checkPermission('municipio_search')}
                />
                <JCardBody>
                    <JCaption>
                        Lista de municípios
                        <JPaginacao 
                            links={links ?? {}}
                            meta={meta ?? {}}
                            handlePageChange={handlePageChange} />
                    </JCaption>
                    <JTable >
                    
                        <JTHead>
                            <tr >
                                <th style={{width: '7rem'}}>
                                    Código IBGE
                                </th>
                                <th>
                                   Nome do município
                                </th>
                                
                                <th className="text-center" style={{width: '5rem'}}>
                                   UF
                                </th>
                                
                            </tr>
                        </JTHead>
                        <JTBody>
                            {dados?.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.cod_ibge}</td>
                                    <td>{item.name}</td>
                                    <td  className="text-center" >{item?.estado?.uf}</td>
                                </tr>
                            ))}                           
                        </JTBody>
                    </JTable>
                </JCardBody>
            </JCard>
        </>
    );
};
