import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useEffect, useRef, useState } from "react";
import { handleNumberOnly, isNumber } from "../../utils/utils";
import { removeNFe, addNFe } from "../../features/manifestoSlice"
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { MensagemModal } from "../../components/Components";

const NFes: React.FC = () => {
    const dispatch = useDispatch();
    const munNFeSelectRef = useRef<HTMLSelectElement>(null);
    const munDescarga = useSelector((state: RootState) => state.manifesto.manifesto.infMunDescarga);
    const selectMunicipiosDescarregamento = useSelector((state: RootState) => state.manifesto.selectMunicipiosDescarregamentos);
    const [notaFiscal, setNotaFiscal] = useState({nfe_numero: '', nfe_codigo_barras: '', nfe_municipio_id: '0' });
    const [showAlert, setShowAlert] = useState(false); 
    const [messageAlert, setMessageAlert] = useState('');

    const handleNFeChange = (e: any) => {
        const {name, value} = e.target;
        setNotaFiscal((prev) => ({...prev, [name]: value}))
    }

    const validaPreenchimentoNFe = () => {
        if (notaFiscal.nfe_numero === "") {
            setMessageAlert('Informe uma chave NFe.')
            setShowAlert(true)
            return false;
        }

        if (isNumber(notaFiscal.nfe_numero)) {
            setMessageAlert('Chave NF-e aceita somente números.')
            setShowAlert(true)
            return false;
        }
        
        if (notaFiscal.nfe_codigo_barras?.trim() !== "") {
            if (isNumber(notaFiscal.nfe_codigo_barras)) {
                setMessageAlert('Seg. Cod. Barras aceita somente números.')
                setShowAlert(true)
                return false;
            }
        }

        if (notaFiscal.nfe_numero.substring(20,22) != '55') {
            setMessageAlert('Chave informada não é uma NF-e.')
            setShowAlert(true)
            return false;
        }

        if (notaFiscal.nfe_numero.length != 44) {
            setMessageAlert('Chave deve conter 44 caracteres.')
            setShowAlert(true)
            return false;
        }

        if (munDescarga.flatMap((item) => item.infNFe).find((item:any) => item.chNFe === notaFiscal.nfe_numero)) {
            setMessageAlert('Chave já foi adicionada.')
            setShowAlert(true)
            return false; 
        }

        setMessageAlert('')
        setShowAlert(false)
        return true;
    }
    
    const handleRemoveNFe = (municipio_id: number, chNFe: string) => {
        if (window.confirm('Deseja realmente remover?')) {
            dispatch(removeNFe({ municipio_id: municipio_id, chNFe: chNFe }));
        }
    }
    
    const handleAddNFe = () => {
        
        if (munNFeSelectRef.current && munNFeSelectRef.current?.value !== "") {
            const value = munNFeSelectRef?.current?.value || '0'
            const nomeMunicipio = munNFeSelectRef.current?.options[munNFeSelectRef.current?.selectedIndex].text || '';
            
            if (validaPreenchimentoNFe()) {

                dispatch(addNFe({
                    chNFe: notaFiscal.nfe_numero, 
                    municipio_id: parseInt(notaFiscal.nfe_municipio_id),
                    name: nomeMunicipio,
                    SegCodBarra: notaFiscal.nfe_codigo_barras
                }));
        
                setNotaFiscal({ nfe_codigo_barras: '', nfe_numero: '', nfe_municipio_id: value });
            }
        
        } else {
            window.alert("Selecione um município.");
            return;
        }

    }

    useEffect(() => {
        if (munDescarga?.length > 0) {
            setNotaFiscal({...notaFiscal, nfe_municipio_id: munDescarga[0].municipio_id?.toString()})
        }

    },[munDescarga]);

    return (<>
        <Card>
            <Card.Body >
                <Row className="g-2">
                    <Col md>
                        <Form.Group >
                            <Form.Label>*Municipios</Form.Label>
                            <Form.Select name="nfe_municipio_id" value={notaFiscal.nfe_municipio_id} 
                                onChange={handleNFeChange} ref={munNFeSelectRef}>
                                {selectMunicipiosDescarregamento?.map(item => {
                                    return (<option value={item.id}>{item.name}</option>);
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col md>
                        <Form.Group >
                            <Form.Label>*Chave NF-e</Form.Label>
                            <Form.Control name="nfe_numero" value={notaFiscal.nfe_numero} 
                                onKeyUp={handleNumberOnly} type="text" onChange={handleNFeChange}/>
                        </Form.Group>
                    </Col>

                    <Col md>
                        <Form.Group >
                            <Form.Label>Seg. Cod. Barras</Form.Label>
                            <Form.Control name="nfe_codigo_barras" value={notaFiscal.nfe_codigo_barras} 
                                onKeyUp={handleNumberOnly} type="text" onChange={handleNFeChange}/>
                        </Form.Group>
                    </Col>

                    <Col md={'auto'} className="d-flex align-items-end">
                        <Button variant="primary" onClick={handleAddNFe}>
                            <FontAwesomeIcon icon={faPlus}/> Adicionar
                        </Button>
                    </Col>
                </Row>
                
                <h6 className=" my-3"><span className="text-dark">(100 no máximo)</span></h6>
                <Table striped bordered hover size="sm">
                    <thead className="table-light">
                        <tr>
                            <th>#</th>
                            <th>Municipio</th>
                            <th>Chave NF-e</th>
                            <th>Seg. Cod. Barras</th>
                        </tr>
                    </thead>
                    <tbody>
                        {munDescarga?.map((item) => (
                            <>
                                {item.infNFe?.map((nfe) => {
                                    return (
                                        <tr key={item?.municipio_id}>
                                            <td className="text-center">
                                                <a href="#" className="text-danger" onClick={() => handleRemoveNFe(item.municipio_id, nfe?.chNFe)}>
                                                    <i className="fas fa-trash"></i>
                                                </a>
                                            </td>
                                            <td>{item?.name}</td>
                                            <td>{nfe?.chNFe}</td>
                                            <td>{nfe?.SegCodBarra}</td>
                                        </tr>
                                    )
                                })}
                            </>
                        ))}
                    </tbody>
                </Table>                   
            </Card.Body>
        </Card>
        <MensagemModal
            show={showAlert}
            handleClose={() => setShowAlert(false)}
            mensagem={messageAlert}
      />
    </>)
}

export default NFes;
