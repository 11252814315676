import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { MakeMessageError } from "../../components/Components";

export default function Totalizador(props: any) {
    const formManifesto = useSelector((state: RootState) => state.manifesto.manifesto );
    
    return (
        <>
            <Row className="mb-1 g-2">
                <Col md={3} className="d-flex align-items-center justify-content-end">
                    <Form.Group className="mb-2">
                        <Form.Label>Valor total da mercadoria/carga transportada:</Form.Label>
                    </Form.Group>
                </Col>
                <Col md={2}>
                    <Form.Group className="mb-2">
                        <Form.Control type="number" name="vcarga" value={formManifesto.vcarga} onChange={props.handleChange} />
                    </Form.Group>
                </Col>
                <Col md className="d-flex align-items-center">
                    <MakeMessageError errors={props.errors} field="vcarga"/>
                </Col>
            </Row>
            <Row className="mb-1 g-2">
                <Col md={3} className="d-flex align-items-center justify-content-end">
                    <Form.Group className="mb-2">
                        <Form.Label>Codigo da unidade do Peso Bruto da carga transportada:</Form.Label>
                        
                    </Form.Group>
                </Col>
                <Col md={2}>
                    <Form.Group className="mb-2">
                        <Form.Select name="cunid" value={formManifesto.cunid} onChange={props.handleChange}>
                            <option key="01" value="01">KG</option>
                            <option key="02" value="02">TON</option>
                            
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mb-1 g-2">
                <Col md={3}  className=" d-flex align-items-center justify-content-end">
                    <Form.Group className="mb-2">
                        <Form.Label>Peso Bruto total da carga transportada:</Form.Label>
                    </Form.Group>
                </Col>
                <Col md={2}>
                    <Form.Group className="mb-2">
                        <Form.Control type="number" name="qcarga" value={formManifesto.qcarga} onChange={props.handleChange} />
                    </Form.Group>
                </Col>
                <Col md className="d-flex align-items-center ">
                    <MakeMessageError errors={props.errors} field="qcarga"/>
                </Col>
            </Row>
        </>
    )
}