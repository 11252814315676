import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { handleNumberOnly, validarCPFCNPJ } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export default function LicencaForm() {
    const [form, setForm] = useState({
        nome: '',
        cpfcnpj: '',
        quantidade: '1'
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validarCPFCNPJ(form.cpfcnpj)) {
            alert('CPF/CNPJ inválido');
            return;
        }
        console.log(form);
    };

    return (
        <div className="container">
            <div className="col-md-8 mx-auto">

                <Form onSubmit={handleSubmit}>
                    <Card className="card-primary card-outline">
                        <Card.Header>
                        
                        <h5 className="text-center text-uppercase">Dados do comprador</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row className="g-2">
                                <Col md>   
                                    <Form.Group className="mb-3" controlId="nome">
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control type="text" required maxLength={60} autoFocus value={form.nome} onChange={handleChange} name="nome"/>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-3" controlId="cpfcnpj">
                                        <Form.Label>CPF/CNPJ</Form.Label>
                                        <Form.Control type="text" required maxLength={14} onKeyUp={handleNumberOnly} value={form.cpfcnpj} onChange={handleChange} name="cpfcnpj"/>
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="quantidade">
                                        <Form.Label>Quantidade</Form.Label>
                                        <Form.Select required value={form.quantidade} name="quantidade" onChange={handleSelect}>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>    
                        <Card.Footer>
                            <div className="row justify-content-end">
                                <div className="col-md">
                                    <Link to="/licenca" className="btn btn-secondary me-2"> <FontAwesomeIcon icon={faArrowLeft} /> Voltar</Link>
                                </div>
                                <div className="col-md-auto">
                                    <Button variant="primary" type="submit"> <FontAwesomeIcon icon={faQrcode} /> Gerar QRCode</Button>
                                </div>
                            </div>
                            
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
        </div>
    )
}