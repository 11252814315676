import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { handleNumberOnly, validarCPFCNPJ } from "../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store";
import { useRef } from "react";
import { addAuthDownload, removeAuthDownload } from "../../features/manifestoSlice";

export default function AutorizacaoDownload() {
    const listaAuthDownload = useSelector((state: RootState) => state.manifesto.manifesto.authDownload);
    const cpfCnpjRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();

    const handleAddAutorizacaDownload = () => {
        const documento = cpfCnpjRef.current?.value || '';

        if (documento === "") {
            window.alert('Informe um CPF/CNPJ.');
            return;
        }
        
        if (!validarCPFCNPJ(documento)) {
            window.alert('CPF/CNPJ inválido.');
            return;
        }

        if (listaAuthDownload.length >= 10) {
            window.alert('Limite máximo de 10 autorizações de download atingido.');
            return;
        }

        if (listaAuthDownload.find(item => item.cpfcnpj === documento)) {
            window.alert('CPF/CNPJ já adicionado.');
            return; 
        }

        dispatch(addAuthDownload({cpfcnpj: documento}));
        
        if (cpfCnpjRef.current) {
            cpfCnpjRef.current.value = '';
        }
    }

    const handleRemoveAutorizacaoDownload = (cpfCnpj: string) => {
        if (window.confirm('Deseja realmente remover?')) {
            dispatch(removeAuthDownload(cpfCnpj));
        }
    }

    return <>
        <Card className="card-primary card-outline mb-3">
            <Card.Body>

                <Row className=" g-2">
                    <Col md={2} className="">
                        <Form.Group className="mb-3" >
                            
                            <Form.Control placeholder="CPF/CNPJ" name="cpfcnpj" maxLength={14} type="text" onKeyUp={handleNumberOnly} ref={cpfCnpjRef} />
                        </Form.Group>
                    </Col>
                    <Col md={2} >
                    <Form.Group className="mb-3">

                        <Button variant="primary" onClick={handleAddAutorizacaDownload}><FontAwesomeIcon icon={faPlus}/>  Adicionar</Button>
                    </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <h6>(10) no máximo</h6>
                </Row>

                <Table striped bordered hover size="sm">
                    <thead className="table-light">
                        <tr>
                            <th className="text-center" style={{width: '2.5rem'}}>#</th>
                            <th>CPF/CNPJ</th>
                        </tr>
                    </thead>

                    <tbody>
                        {listaAuthDownload?.map((autorizacao, index) => (
                            <tr key={index}>
                                <td className="text-center">
                                    <a href="javasctipt:void(0)" className="text-danger" onClick={() => handleRemoveAutorizacaoDownload(autorizacao.cpfcnpj)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </a>
                                </td>
                                <td>{autorizacao.cpfcnpj}</td>
                            </tr>
                        )) } 
                    </tbody>
                </Table>
            </Card.Body>
        </Card>

    </>
}