import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import "../../index.css";
import { useEffect, useRef, useState } from "react";
import auxService from "../../services/AuxService";
import { Condutor, CondutorInterface } from "../../interfaces";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { addCondutor, removeCondutor } from "../../features/manifestoSlice";
import { validarCPFCNPJ } from "../../utils/utils";
import { JConfirmDelete, MakeMessageError, MensagemModal } from "../../components/Components";


export default function Condutores() {
    const dispatch = useDispatch();
    const [selectCondutores, setSelectCondutores] = useState<CondutorInterface[]>([]);
    const [showModal, setShowModal] = useState(false); 
    const [showAlert, setShowAlert] = useState(false); 
    const [messageAlert, setMessageAlert] = useState('');
    const [selected, setSelected] = useState({
        nome: '',
        cpf: ''
    });
    const listaCondutores = useSelector((state: RootState) => state.manifesto.manifesto.modal_rodoviario.tracao.condutores);
    const errors = useSelector((state: RootState) => state.manifesto.listaErrors);
    const [formCondutor, setFormCondutor] = useState({
        nome: '',
        cpf: ''
    })
    const selectedCondutorRef = useRef<HTMLSelectElement>(null);

    const handleAddSelectedCondutor = ()=> {
        const value = selectedCondutorRef?.current?.value || '0'
        const condutor = selectedCondutorRef.current?.options[selectedCondutorRef.current?.selectedIndex].text || '|';
        let nome = '';
        let cpf = '';

        if (value === '0') {
            window.alert('Selecione um condutor!');
            return;
        }

        nome = condutor.split('|')[0].trim();
        cpf = condutor.split('|')[1].trim();

        if (validaPreenchimento(nome, cpf)) {
            dispatch(addCondutor({
                CPF: cpf,
                xNome: nome
            }));
        }
    }
    
    const handleAddCondutor = () => {
       
        if (validaPreenchimento(formCondutor.nome, formCondutor.cpf)) {

            dispatch(addCondutor({
                CPF: formCondutor.cpf,
                xNome: formCondutor.nome
            }));
            
            setFormCondutor({
                cpf: '',
                nome: ''
            })
        }
    }

    const validaPreenchimento = (nome: string, cpf: string) => {
        if (nome === '') {
            //window.alert('Informe o nome do condutor');
            setMessageAlert('Informe o nome do condutor')
            setShowAlert(true)
            return false;
        }

        if (nome.length<=1) {
            setMessageAlert('O nome do condutor deve ter pelo menos 2 caracteres')
            setShowAlert(true)
            return false;
        }

        if (cpf === '') {
            setMessageAlert('Informe o CPF do condutor')
            setShowAlert(true)
            return false;
        }

        if (!validarCPFCNPJ(cpf)) {
            
            setMessageAlert('CPF inválido')
            setShowAlert(true)
            return false;
        }

        if (listaCondutores.length >= 10) {
            setMessageAlert('Número máximo de condutores atingido!')
            setShowAlert(true)
            return false;
        }

        if (listaCondutores.find((item: any) => item.CPF === cpf)) {
            setMessageAlert('Condutor com esse CPF já foi adicionado!')
            setShowAlert(true)
            return false;
        }
        setMessageAlert('')
        setShowAlert(false)
        return true;
    }

    const handleOpenModal = (cpf: string, nome: string) => {
        setSelected({
            cpf: cpf,
            nome: nome
        });
        setShowModal(true);
    }

    const handleRemoveCondutor = (cpf: string) => {
        dispatch(removeCondutor(cpf));
        setShowModal(false);
    }

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        
        setFormCondutor((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setSelected({
            cpf: '',
            nome: '',
        });
    }

    useEffect(()=>{
        auxService.getCondutores().then((res) => {
            setSelectCondutores(res.data)
        })
    }, []);

    return (<>
        <Card className="card-primary card-outline mb-3">
            <Card.Body>

                <Row className="g-2 mb-4">
                <Col md={5}>
                        <Form.Group>
                            <Form.Label>Condutores cadastrados</Form.Label>
                            <Form.Select ref={selectedCondutorRef}>
                                <option value="0">Selecione um condutor</option>
                                {selectCondutores?.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name} | {item.cpf}</option>
                                )
                                )}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={'auto'} className="d-flex align-items-end">
                        <Button onClick={handleAddSelectedCondutor} title="Adicionar um condutor a lista" ><FontAwesomeIcon icon={faPlus}/> Adicionar</Button>
                    </Col>
                </Row>
                <hr />
                    <fieldset className="group-box">
                        <legend>Adicionar manualmente</legend>
                        <Row className="g-2 mb-3">
                            <Col md={7}>
                                <Form.Group>
                                    <Form.Label>Nome do condutor</Form.Label>
                                    <Form.Control  name="nome" onChange={handleChange} value={formCondutor.nome} maxLength={60} minLength={2}/>
                                </Form.Group>
                            </Col>

                            <Col md={3} >
                                <Form.Group >
                                    <Form.Label>CPF</Form.Label>
                                    <Form.Control name="cpf" onChange={handleChange} value={formCondutor.cpf} maxLength={11} />
                                </Form.Group>
                            </Col>

                            <Col md={'auto'} className="d-flex align-items-end">
                                <Button onClick={handleAddCondutor} title="Adicionar um condutor a lista"><FontAwesomeIcon icon={faPlus}/> Adicionar</Button>
                            </Col>

                        </Row>
                    </fieldset>
                
                <h6>(10 no máximo)</h6>
                <MakeMessageError errors={errors} field="modal_rodoviario_tracao_condutores" />
                <Table striped bordered hover size="sm">
                    <thead className="table-light">
                        <tr>
                            <th className="text-center" style={{width: '2.5rem'}}>#</th>
                            <th>Nome do condutor</th>
                            <th>CPF</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listaCondutores?.map((item: Condutor) => (
                            <tr key={item.CPF}>
                                <td className="text-center">
                                    <a href="javasctipt:void(0)" className="text-danger" onClick={() => handleOpenModal(item.CPF, item.xNome)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </a>
                                </td>
                                <td>{item.xNome}</td>
                                <td>{item.CPF}</td>
                            </tr>
                        )) } 
                    </tbody>
                </Table>

            </Card.Body>
        </Card>

        <JConfirmDelete 
            showModal={showModal} 
            handleCloseModal={handleCloseModal} 
            handleDelete={handleRemoveCondutor} 
            selectedObject={selected.nome} 
            id={selected.cpf}
        />

    <MensagemModal
        show={showAlert}
        handleClose={() => setShowAlert(false)}
        mensagem={messageAlert}
      />
    </>)
}