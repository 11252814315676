import {  faMoneyBill1Wave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import auxService from "../../services/AuxService";

interface Tenant {
    id: number;
    grupo: string;
    name: string;
    data_vencimento: string;
    limite_empresas: number;
    active: boolean;
}

const initial: Tenant = {
    id: 0,
    grupo: '',
    name: '',
    data_vencimento: '',
    limite_empresas: 0,
    active: false
};

export default function LicencaIndex() {

    const [tenant, setTenant] = useState<Tenant>(initial);

    useEffect(() => {
        fetchTenant();
    }, []);

    const fetchTenant = async () => {
         auxService.getTenant().then(res => {
            setTenant(res);
            console.log(res);
         });
    
    }

    return (
        <div className="container mt-3">
            
            
            <div className="d-flex justify-content-end">

            <Link to="/licenca/create" className="">
                <Button variant="light" className=""> <FontAwesomeIcon className="text-primary" icon={faMoneyBill1Wave} /> Comprar Licença</Button>
            </Link>
            </div>
            <hr />
            <Table striped bordered hover size="sm">
                <thead className="table-light">
                    <tr>
                        <th>CNPJ Raiz</th>
                        <th>Nome do Tenant</th>
                        <th>Data de vencimento</th>
                        <th>Limite de empresas</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {tenant.id > 0  && (
                        <tr key={tenant.id}>
                            <td>{tenant.grupo}</td>
                            <td>{tenant.name}</td>
                            <td>{new Date(tenant.data_vencimento).toLocaleDateString('pt-BR')}</td>
                            <td>{tenant.limite_empresas}</td>
                            <td>{tenant.active ? 'Ativa' : 'Inativa'}</td>
                        </tr>
                    )}  
                </tbody>
            </Table>
        </div>
    )
}