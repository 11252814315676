import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

function formatDate(dataString: string) : string {
    if (!dataString) return "";
    const date = parseISO(dataString);
    return format(date, "dd/MM/yyyy", { locale: ptBR });    
   
}

function formatMoeda (valor: number, decimal: number = 2): string  {
    return parseFloat(valor.toString()).toLocaleString('pt-BR', 
        {
            currency: 'BRL', 
            minimumFractionDigits: decimal,
        }
    )
}

function ajustarOrdenacaoPaginada(page: string | null, initOrderBy: any, setOrderBy: any) {
    if (page?.includes('ordering')) {
        const order = page.split('=')[1];
        if (order.includes('-')) {
            let name = order.split('-')[1];

            if (name.includes('page')) {
                name = name.split('&')[0];
            }

            if (name.includes('-')) {
                name.replace('-', '');
            }

            setOrderBy({
                ...initOrderBy,
                [name]: { name, order: 'asc', label: initOrderBy[name as keyof typeof initOrderBy].label }
            });
        } else {
            const name = order.includes('page') ? order.split('&')[0] : order;

            setOrderBy({
                ...initOrderBy,
                [name]: { name, order: '-', label: initOrderBy[name as keyof typeof initOrderBy].label }
            });
        }
    }
}


function handleNumberOnly(e: React.KeyboardEvent<HTMLInputElement>) {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    const numberOnly = value.replace(/\D/ig, '');
    element.value = numberOnly;
 }

 function isNumber(value: string) {
    return /\D/gi.test(value);
 }


function validarCPF(cpf: string): boolean {
    cpf = cpf.replace(/[^\d]/g, '');
    
    if (cpf.length !== 11) return false;
    
    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let digit = 11 - (sum % 11);
    if (digit >= 10) digit = 0;
    if (digit !== parseInt(cpf.charAt(9))) return false;
    
    sum = 0;
    for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    digit = 11 - (sum % 11);
    if (digit >= 10) digit = 0;
    if (digit !== parseInt(cpf.charAt(10))) return false;
    
    return true;
}

function validarCNPJ(cnpj: string): boolean {
    cnpj = cnpj.replace(/[^\d]/g, '');
    
    if (cnpj.length !== 14) return false;
    
    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    let digits = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;
    
    for (let i = size; i >= 1; i--) {
        sum += parseInt(numbers.charAt(size - i)) * pos--;
        if (pos < 2) pos = 9;
    }
    
    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result !== parseInt(digits.charAt(0))) return false;
    
    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;
    
    for (let i = size; i >= 1; i--) {
        sum += parseInt(numbers.charAt(size - i)) * pos--;
        if (pos < 2) pos = 9;
    }
    
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result !== parseInt(digits.charAt(1))) return false;
    
    return true;
}

function validarCPFCNPJ(value: string) {
    if (value.length === 11) {
        return validarCPF(value);
    } else if (value.length === 14) {
        return validarCNPJ(value);
    }
    return false;
}


function tratarErrors(errors: any, prefixos: string[] ) {
    let listaErros = {}

    prefixos.forEach(prefixo => {
        let newObj = {};
        const errorsCustom = Object.fromEntries(
            Object.entries(errors).filter(([key]) => key.startsWith(prefixo))
        );
        
        const erroWithOut = Object.fromEntries(
            Object.entries(errors).filter(([key]) => !key.startsWith(prefixo))
        );
    
        if (Object.keys(errorsCustom).length > 0) {
           
            newObj = Object.entries(errorsCustom).reduce((acc: { [key: string]: any }, [key, value]) => {
                let newKey = key;
                acc[newKey.replaceAll('.', '_')] = value;
                delete acc[key];
                return acc;
            }, {});
        }
        listaErros = {...erroWithOut, ...newObj}
    });
    
    return listaErros;
}

export { formatDate, formatMoeda, ajustarOrdenacaoPaginada, handleNumberOnly, validarCPFCNPJ, tratarErrors, isNumber};
