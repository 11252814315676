import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store";
import { useRef } from "react";
import { addLacreManifesto, removerLacreManifesto } from "../../features/manifestoSlice";
import { Link } from "react-router-dom";

export default function LacresManifesto() {
    const listaLacresManifesto = useSelector((state: RootState) => state.manifesto.manifesto.lacres);
    const numero = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();

    const handleAddLacreManifesto = () => {
        const numeroLacre = numero.current?.value || '';
        if (numeroLacre === "") {
            window.alert('Informe o número do lacre.');
            return;
        }
        if (listaLacresManifesto.find(item => item.numero === numeroLacre)) {
            window.alert('Número do lacre já foi adicionado.');
            return;
        }
        
        dispatch(addLacreManifesto({numero: numeroLacre}));

        if (numero.current) {
            numero.current.value = '';
        }
    }

    const handleRemoveLacreManifesto = (numeroLacre: string) => {
        if (window.confirm('Deseja realmente remover?')) {
            dispatch(removerLacreManifesto(numeroLacre));
        }
    }

    return <>
        <Card className="card-primary card-outline mb-3">
            <Card.Body>

                <Row className=" g-2">
                    <Col md={2} className="">
                        <Form.Group className="mb-3" >
                            <Form.Control placeholder="Número do lacre" maxLength={60} type="text"  ref={numero} />
                        </Form.Group>
                    </Col>
                    <Col md={2} >
                    <Form.Group className="mb-3">

                        <Button variant="primary" onClick={handleAddLacreManifesto}><FontAwesomeIcon icon={faPlus}/>  Adicionar</Button>
                    </Form.Group>
                    </Col>
                </Row>

                <Table striped bordered hover size="sm">
                    <thead className="table-light">
                        <tr>
                            <th className="text-center" style={{width: '2.5rem'}}>#</th>
                            <th>Número do lacre</th>
                        </tr>
                    </thead>

                    <tbody>
                        {listaLacresManifesto.map((item, index) => (
                            <tr key={index}>
                                <td className="text-center">
                                    <Link to={''}  className="text-danger" onClick={() => handleRemoveLacreManifesto(item.numero)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Link>
                                </td>
                                <td>{item.numero}</td>
                            </tr>
                        )) } 
                    </tbody>
                </Table>
            </Card.Body>
        </Card>

    </>
}