import React, {useContext} from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { 
  faCheck, faRightFromBracket, faQrcode, 
  faUser, faSync, faTruckMoving, faUsers, 
  faBuilding, faGlobe, faMap, faCity, 
  faUserGroup, faLock 
} from "@fortawesome/free-solid-svg-icons";
import * as Icon from 'react-bootstrap-icons';

const Menu: React.FC = () => {
    const authContext = useContext(AuthContext);

    return (
       
        <Navbar bg="dark" variant="dark" expand="md" className="py-1 shadow-sm">
        {/* <Navbar bg="light" variant="light" expand="md" className="py-1 shadow-sm"> */}
          <Container fluid={true} >
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/">Principal</Nav.Link>
                
                <NavDropdown title="Manifesto" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/manifesto"> 
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faTruckMoving} className="text-primary me-1 " style={{maxWidth: '1.25rem', minWidth: '1.25rem'}} /> 
                      <span className="">MDF-e</span>
                    </div>
                  </NavDropdown.Item>

                  <NavDropdown.Item as={Link} to="/status" >
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faSync} className="text-success me-1 " style={{maxWidth: '1.25rem', minWidth: '1.25rem'}} /> 
                      <span className="">Status do Serviço</span>
                    </div>
                  </NavDropdown.Item>

                  <NavDropdown.Item as={Link} to="/condutor"> 
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faUsers} className="text-warning me-1 " style={{maxWidth: '1.25rem', minWidth: '1.25rem'}} /> 
                      <span className="">Condutores</span>
                    </div>
                  </NavDropdown.Item>
                  {authContext?.userLogado.is_superuser === 1 && (

                    <NavDropdown.Item as={Link} to="/empresa"> 
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faBuilding} className="text-success me-1 " style={{maxWidth: '1.25rem', minWidth: '1.25rem'}} /> 
                        <span className="">Empresas</span>
                      </div>
                    </NavDropdown.Item>
                  )}
                  
                 
                  <NavDropdown.Divider />
                  
                  <NavDropdown.Item as={Link} to="/pais"> 
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faGlobe} className="text-danger me-1 " style={{maxWidth: '1.25rem', minWidth: '1.25rem'}} /> 
                      <span className="">Países</span>
                    </div>
                  </NavDropdown.Item>
                  
                  <NavDropdown.Item as={Link} to="/estado"> 
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faMap} className="text-info me-1 " style={{maxWidth: '1.25rem', minWidth: '1.25rem'}} /> 
                      <span className="">Estados</span>
                    </div>
                  </NavDropdown.Item>
                  
                  <NavDropdown.Item as={Link} to="/municipio"> 
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faCity} className="text-success me-1 " style={{maxWidth: '1.25rem', minWidth: '1.25rem'}} /> 
                      <span className="">Municipios</span>
                    </div>
                  </NavDropdown.Item>

                  

                </NavDropdown>
                {authContext?.userLogado.is_superuser === 1 && (
                  <NavDropdown title="Segurança" id="basic-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/profile"> 
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faUserGroup} className="text-primary me-1 " style={{maxWidth: '1.25rem', minWidth: '1.25rem'}} /> 
                        <span className="">Perfis de usuário</span>
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/user"> 
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faUser} className="text-success me-1 " style={{maxWidth: '1.25rem', minWidth: '1.25rem'}} /> 
                        <span className="">Usuários</span>
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/permission"> 
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faLock} className="text-danger me-1 " style={{maxWidth: '1.25rem', minWidth: '1.25rem'}} /> 
                        <span className="">Permissões</span>
                      </div>
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </Nav>

              <Nav className="ms-auto" >
                <NavDropdown  title={<Icon.BuildingFillCheck className=""/>}  id="basic-nav-dropdown" align="end" >
                    
                    {authContext?.empresas?.map((empresa: any) => (
                        
                        <NavDropdown.Item 
                          key={empresa.id} onClick={() => authContext.changeEmpresa(empresa.id)}  > {
                            empresa.id === authContext?.empresa ? <FontAwesomeIcon icon={faCheck} /> : ''
                        } {empresa.xfant}</NavDropdown.Item>
                    ))}
                  
                </NavDropdown>
              </Nav>


              <Nav className="ms-2" >
                <NavDropdown title={authContext?.user} id="basic-nav-dropdown" align="end">
                    {authContext?.userLogado.is_superuser === 1 && <NavDropdown.Item  as={Link} to="/licenca"> <FontAwesomeIcon icon={faQrcode} className="text-success"/> Licenças</NavDropdown.Item> }
                    {authContext?.isAuthenticated && <NavDropdown.Item  onClick={authContext.logout}><FontAwesomeIcon  className="text-danger" icon={faRightFromBracket} />  Sair</NavDropdown.Item> }
                </NavDropdown>
              </Nav>

            </Navbar.Collapse>
          </Container>
        </Navbar>
    );
}

export default Menu;