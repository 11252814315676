import api from "../api/api";   

class AuxService {
    
    async getPermissions() {
        return await api.get(`permissions_disponiveis`).then(res => res.data);
    }

    async getProfiles() {
        return await api.get(`profiles_disponiveis`).then(res => res.data);
    }

    async getEmpresas() {
        return await api.get(`empresas_disponiveis`).then(res => res.data);
    }

    async getEstados() {
        return await api.get(`estado/list/all`).then(res => res.data);
    }

    async getMunicipios(estado_id: number) {
        return await api.get(`municipio/list/${estado_id}`).then(res => res.data);
    }

    async getMunicipiosByUf(uf: string) {
        return await api.get(`municipio/list/uf/${uf}`).then(res => res.data);
    }

    async postRegister(data: any) {
        return await api.post(`register`, data).then(res => res.data);
    }

    async getTenant() {
        return await api.get(`tenant-user`).then(res => res.data);
    }

    async getCondutores() {
        return await api.get('condutor/list/all').then(res => res.data)
    }
    
}

const auxService = new AuxService();

export default auxService;