/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useContext, useState } from "react";
import { faArrowDown, faArrowUp, faClose, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row as RBRow, Pagination, Alert, Table, Card, Col, Form, Spinner, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { cs } from "date-fns/locale";

const JRow = (props: any) => {
    return (
        <RBRow className={`g-2 ${props.className ?? props.className}`}>
            {props.children}
        </RBRow>
    )
}

const JFormGroup = (props: any) => {
    return (
        <Form.Group className={`mb-3 ${props.className ?? props.className}`} controlId={props.controlId}>
            {props.children}
        </Form.Group>
    )
}

const JPaginacao = (props: any | []) => {
    const { links } = props;
    const { meta } = props;
    return (
        <>
            {!links  ? (
                <></>
            ) : (
                <Pagination className="my-1">
                  <Pagination.First disabled={meta.current_page == 1 ? true : false} onClick={() => props.handlePageChange('1')} />
                  <Pagination.Prev disabled={links.prev == null ? true : false} onClick={() => props.handlePageChange(links.prev?.split('?')[1]?.split('=')[1])} />
                  <Pagination.Item active>{meta.current_page}</Pagination.Item>
                  <Pagination.Next disabled={meta.current_page == meta.last_page ? true : false} onClick={() => props.handlePageChange(links.next?.split('?')[1]?.split('=')[1])}  />
                  <Pagination.Last disabled={meta.current_page == meta.last_page ? true : false} onClick={() => props.handlePageChange(meta.last_page)} />
                </Pagination>
              
            )}
        </>
    )

}

const JAlerta = (props: any) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            props.close(false)
        }, props.time || 2000);       

        return () => clearTimeout(timer)
    }, [props.showError])

    return (
        <Alert className="" variant={props.variant} show={props.showError} onClose={() => props.close(false)} dismissible>
            <span>{props.messageError}</span>
        </Alert>
    )
}

const JAlertaErrorFormulario = (props: any) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            props.close(false)
        }, props.time || 5000);       

        return () => clearTimeout(timer)
    }, [props.showError])

    return (
        
        <div role="alert" className={`fade mb-0 p-2 alert alert-danger alert-dismissible ${props.showError ? 'show': ''}`}>
            <span>{props.messageError}</span>
        </div>
    )
}

const JTable = (props: any) => {
    return (
        <div className="table-responsive" style={{ overflowX: 'clip' }}>
        <Table  bordered hover className="caption-top mb-1 " size="sm" >
            {props.children}
        </Table>
        </div>
    )
}    

const JTr = (props: any) => {
    const jstyle = props.style ? props.style : {}
    jstyle.verticalAlign = 'middle'
    return (
        
        <tr className={props.className} style={jstyle}>
            {props.children}
        </tr>
    )
}

const JCaption = (props: any) => {
    return (
        <caption style={{padding: '0', margin: '0', minHeight: '2.938rem'}} 
            className="d-flex fw-bold justify-content-between align-items-center rounded-top-2 border-top border-start border-end px-2" >
            {props.children}
        </caption>
    )
}

const JHeader = (props: any) => {
    const authContext = useContext(AuthContext);

    return (
        <Card.Header className="pb-0 ps-2">
            <JRow className="d-flex justify-center align-items-center">
                {props.urlCreate && (props.permissionCreate || authContext?.userLogado.is_superuser=== 1  || authContext?.userLogado.is_staff === true) &&  (

                    <Col sm="auto">
                        <Form.Group className="mb-2" controlId="descricao">
                            <Link to={props.urlCreate} title="Adicionar [Insert]" className="btn btn-primary rounded-pill">
                                <FontAwesomeIcon icon={faPlus} ></FontAwesomeIcon> 
                            </Link>
                        </Form.Group>
                    </Col>
                )}

                <Col sm="4">
                
                {(props.permissionSearch || authContext?.userLogado.is_superuser === 1 || authContext?.userLogado.is_staff === true) &&  (
                    
                    <Form.Group className="mb-2"  controlId="descricao">
                        <Form.Control type="search" 
                            value={props.search}
                            placeholder="Procurar" 
                            aria-label="Procurar"
                            onChange={(e) => props.setSearch(e.target.value)}
                            />
                    </Form.Group>

                )}
                </Col>
                <Col md={"auto"} className="ms-auto"  >
                    <Spinner style={{ float: 'right' }}  variant="success" hidden={!props.showSpinner} 
                        animation="border" role="status"/>
                </Col>
                {props.children}
                
            </JRow>
        </Card.Header>
    )
}

const JConfirmDelete = (props: any) => {
    return (
        <Modal show={props.showModal} onHide={props.handleCloseModal} backdrop='static' centered>
            <Modal.Header closeButton>
                <Modal.Title>Remover</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Confirma a remoção <strong>{props.selectedObject}</strong>?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleCloseModal}>
                    Cancelar
                </Button>
                <Button variant="danger" onClick={() => props.handleDelete(props.id)}>
                    Remover
                </Button>
            </Modal.Footer>
        </Modal>        
    )
}

interface MensagemModalProps {
    show: boolean;
    handleClose: () => void;
    mensagem: string;
  }

const MensagemModal: React.FC<MensagemModalProps> = ({ show, handleClose, mensagem }) => {
    return (
        <Modal show={show} onHide={handleClose} centered backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title>Aviso</Modal.Title>
            </Modal.Header>
            
            <Modal.Body>{mensagem}</Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Fechar
                </Button>
            </Modal.Footer>
      </Modal>
    );
  };
  

const JCard = (props: any) => {
    return (
        <Card className="shadow card-primary card-outline">
            {props.children}
        </Card>
    )
}

const JCardBody = (props: any) => {
    return (
        <Card.Body className="p-2">
            {props.children}
        </Card.Body>
    )
}

const JTHead = (props: any) => {
    return (
        <thead className="table-light">
            {props.children}
        </thead>
    )
}

const JTBody = (props: any) => {
    return (
        <tbody className="table-group-divider">
            {props.children}
        </tbody>
    )
}

const JThOrdenado = (props : any) => {
    const { orderBy, field } = props;
    return (
        <div className="d-flex justify-content-start">
            
            {orderBy[field]  && orderBy[field].order !== '' && (
                <div >
                    
                    {orderBy[field].order !== '' && (
                        <span className="p-1" title="Remover ordenação">
                            <a className="text-danger" href="javascript:void(0)" onClick={() => {
                                    props.searchFilter()
                                    props.clearnOrderBy(orderBy[field])}
                                } >
                                <FontAwesomeIcon icon={faClose} />
                            </a>
                        </span>

                    )}
                    
                    <span className="p-1" title={(orderBy[field].order === '' || orderBy[field].order === 'asc') ? 'Decrescente': 'Crescente'}>

                        <a href="javascript:void(0)" onClick={() => props.searchFilter(`ordering=${orderBy[field].order?.replace('asc','')}${orderBy[field].name}`)} >
                            {(orderBy[field].order === 'asc')  && (
                                <FontAwesomeIcon icon={faArrowUp} />
                            )}
                            {orderBy[field].order === '-' && (
                                <FontAwesomeIcon icon={faArrowDown} />
                            )}
                        </a>
                    </span>
                </div>
            )}
            <div>
                {orderBy[field] && orderBy[field].order === '' && (
                    <a className="text-decoration-none"  href="javascript:void(0)" 
                        title="Ordenar"
                        onClick={() => props.searchFilter(`ordering=${orderBy[field].name}`)}>
                        {orderBy[field].label}
                    </a>
                    )
                } 

                {orderBy[field] && orderBy[field].order !== '' && (
                        <span>
                            {orderBy[field].label}
                        </span>
                    )
                }

            </div>

        </div>
    )
}

function MakeMessageError( {errors, field} : any ) {
    return (
      <>
          
           {errors[field] && (
              
              <ul className="errorfield mb-1" id={`error_${field}`}>
                  {errors[field].map((error:any, index: any) => (
                      <li key={index} className="text-danger">{error}</li>
                  ))}
              </ul>
          )}
      </>
    );
  }

export { 
    JRow, 
    JPaginacao, 
    JAlerta, 
    JTable, 
    JCaption, 
    JHeader, 
    JConfirmDelete,
    JCard,
    JCardBody,
    JTHead,
    JTBody,
    JThOrdenado,
    JTr,
    JFormGroup,
    MakeMessageError,
    JAlertaErrorFormulario,
    MensagemModal,
};
