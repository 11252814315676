import api, {BASE_URL} from "../api/api";

class EmpresaService {
    async getAllPaginate(search: string, page: string | null) {
        let q = '';
        if (search?.length > 0) {
            q = `?q=${search}`;
            q += page ? `&page=${page}` : "";
            return await api.get(`/empresa${q}`).then(r => r.data);
        } else {
            if (page) {
                return await api.get(`/empresa?page=${page}`).then(r => r.data);
            } else {
                return await api.get(`empresa`).then(r => r.data);
            }
        }
    }

    async get(id: string) {
        return await api.get(`/empresa/${id}`).then(response => response.data);
    }

    async create(data: any) {
        return await api.post("/empresa", data).then(response => response.data);
    }

    async update(id: string, data: any) {
        return await api.put(`/empresa/${id}`, data).then(response => response.data);
    }

    async delete(id: string) {
        return await api.delete(`/empresa/${id}`).then(response => response.data);
    }

    async activate(id: number | string) {
        return await api.patch(`/empresa/activate/${id}`).then(response => response.data);
    }

    async deactivate(id: number | string) {
        return await api.patch(`/empresa/desactivate/${id}`).then(response => response.data);
    }
}

const empresaService = new EmpresaService();

export default empresaService;