import {useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { CondutorInterface as iData } from "../../interfaces";
import condutorService from "../../services/CondutorService";
import { JAlerta } from "../../components/Components";


const initial = {
    id: '',
    name: '',
    cpf: '',
    capacidade: 0,
    placa: '',
    renavam: '',
    tara: 0,
    active: true
}

const initialFieldsError = {
  cpf: [],
  name: [], 
  capacidade: [],
  placa: [],
  renavam: [],
  tara: [],
}

export default function FormCondutor() {
    const navigate = useNavigate()
    const { id } = useParams<{id: string}>();
    const [showError, setShowError] = useState(false);
    const [showMessageError, setShowMessageError] = useState('');
    const [errors, setErrors] = useState(initialFieldsError);
    const [data, setData] = useState<iData>(initial)

    useEffect(() => {
        
        if (id) {
          condutorService.get(id)
            .then(data => setData(data))
            .catch(err => navigate('/condutor'))
        }
    }, [id])

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (id) {
          condutorService.update(id, data).then( data => {
                navigate('/condutor')
            }).catch(err => {
                if (err.response.status === 422) {
                    setErrors(err.response.data)
                }
                if (err.response.status === 403) {
                    setShowError(true)

                    setShowMessageError(err.response.data.message)
                }
                navigate(`/condutor/${id}`)
            })
            return;
        } else {
            condutorService.create(data).then( data => {
                navigate('/condutor')
            }).catch(err => {
                
                if (err.response.status === 422) {
                    setErrors(err.response.data)
                }
                if (err.response.status === 403) {
                    setShowError(true)
                    setShowMessageError(err.response.data.message)
                }
                navigate("/condutor/create")
            })
        }
    }

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        
        setData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    return (
        <div>
            
            <JAlerta showError={showError} close={setShowError} messageError={showMessageError} /> 

            <Form onSubmit={handleSubmit} noValidate>
                <Card className="card-success card-outline">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        {/* <div className="h5 py-0 m-0 text-muted">{id ? 'Editar' : 'Novo'} Condutor</div> */}

                        <div className="my-0">
                            <Button variant={id ? 'success': 'primary'} type="submit"><FontAwesomeIcon icon={faSave} /> {id ? 'Salvar': 'Criar' }</Button>
                            <Link to="/condutor" className="btn btn-secondary ms-1"> <FontAwesomeIcon icon={faArrowLeft} /> Voltar</Link>
                        </div>
                        <div className="h5 py-0 m-0 text-muted">{id ? 'Editar' : 'Novo'} Condutor</div>
                    </Card.Header>

                    <Card.Body>
                            <Row className="g-2">
                              <Col md={2}>
                                    <Form.Group className="mb-3" controlId="cpf">
                                        <Form.Label>CPF</Form.Label>
                                        <Form.Control maxLength={11} name="cpf" value={data.cpf} autoFocus
                                            
                                            type="text" onChange={handleChange} />
                                        {errors.cpf && (
                                            <ul className="errorfield">
                                                {errors.cpf.map((error, index) => (
                                                    <li key={index} className="text-danger">{error}</li>
                                                ))}
                                            </ul>
                                        )}

                                    </Form.Group>
                                </Col>
                                <Col md>
                                    <Form.Group className="mb-3" controlId="nome">
                                        <Form.Label>Nome do condutor</Form.Label>
                                        <Form.Control maxLength={50} name="name" value={data.name} 
                                            type="text" onChange={handleChange}  />

                                        {errors.name  && (
                                            <ul className="errorfield">
                                                {errors.name.map((error, index) => (
                                                    <li key={index} className="text-danger">{error}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="g-2">
                              <Col md={2}>
                                    <Form.Group className="mb-3" controlId="placa">
                                        <Form.Label>Placa</Form.Label>
                                        <Form.Control maxLength={7} name="placa" value={data.placa} 
                                            
                                            type="text" onChange={handleChange} />
                                        {errors.placa && (
                                            <ul className="errorfield">
                                                {errors.placa.map((error, index) => (
                                                    <li key={index} className="text-danger">{error}</li>
                                                ))}
                                            </ul>
                                        )}

                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="renavam">
                                        <Form.Label>Renavam</Form.Label>
                                        <Form.Control maxLength={11} name="renavam" value={data.renavam} 
                                            type="text" onChange={handleChange}  />

                                        {errors.renavam  && (
                                            <ul className="errorfield">
                                                {errors.renavam.map((error, index) => (
                                                    <li key={index} className="text-danger">{error}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>

                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="capacidade">
                                        <Form.Label>Capacidade(kg)</Form.Label>
                                        <Form.Control  name="capacidade" value={data.capacidade} 
                                            type="number" onChange={handleChange}  />

                                        {errors.capacidade  && (
                                            <ul className="errorfield">
                                                {errors.capacidade.map((error, index) => (
                                                    <li key={index} className="text-danger">{error}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>

                                <Col md={2}>
                                    <Form.Group className="mb-3" controlId="tara">
                                        <Form.Label>Tara(kg)</Form.Label>
                                        <Form.Control  name="tara" value={data.tara} 
                                            type="number" onChange={handleChange}  />

                                        {errors.tara  && (
                                            <ul className="errorfield">
                                                {errors.tara.map((error, index) => (
                                                    <li key={index} className="text-danger">{error}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row  className="g-2">
             
                                <Col md>
                                    <Form.Group className="mb-3" controlId="active">
                                        <Form.Switch type="switch" name="active" label="Ativo?" checked={data.active} 
                                            onChange={(e) => setData({...data, active: e.target.checked})} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            
                            
                    </Card.Body>
                    {/* <Card.Footer>
                    <Button variant={id ? 'success': 'primary'} type="submit"><FontAwesomeIcon icon={faSave} /> {id ? 'Salvar': 'Criar' }</Button>
                    <Link to="/condutor" className="btn btn-secondary ms-1"> <FontAwesomeIcon icon={faArrowLeft} /> Voltar</Link>
                    </Card.Footer> */}
                </Card>
            </Form>
        </div>
    );
}
