import axios from "axios";
import { useNavigate } from 'react-router-dom';

const port = process.env.NODE_ENV === 'development' ? `:${process.env.REACT_APP_PORT}` : '';

export const BASE_URL = `${process.env.REACT_APP_URL_BASE}${port}/api/`;

const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Cache-Control": "no-cache",
    },

    withCredentials: true,
    withXSRFToken: true
    
});


api.interceptors.request.use(
    async config => {
        const token = localStorage.getItem('token');
        const empresa =  localStorage.getItem('empresa') || '0';
        
        if (token !== "") {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["manifesto-empresa-logada"] = empresa;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Redireciona para login se não autenticado
        const navigate = useNavigate();
        navigate('/login');
      }
      return Promise.reject(error);
    }
  );
export default api;