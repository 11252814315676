import api from "../api/api";

class UserService {
    async getAllPaginate(search: string, page: string | null) {
        let q = '';
        if (search?.length > 0) {
            q = `?q=${search}`;
            q += page ? `&page=${page}` : "";
            return await api.get(`/users${q}`).then(r => r.data);
        } else {
            if (page) {
                return await api.get(`/users?page=${page}`).then(r => r.data);
            } else {
                return await api.get(`/users`).then(r => r.data);
            }
        }
    }

    async get(id: string) {
        return await api.get(`/users/${id}`).then(response => response.data);
    }

    async create(data: any) {
        return await api.post("/users", data).then(response => response.data);
    }

    async update(id: string, data: any) {
        
        return await api.put(`/users/${id}`, data).then(response => response.data);
    }

    async delete(id: string) {
        return await api.delete(`/users/${id}`).then(response => response.data);
    }
}

const userService = new UserService();

export default userService;