import { useEffect, useState, useContext} from "react";
import { PaisInterface as iData } from "../../interfaces";
import paisService from "../../services/PaisService";
import { 
    JCaption, 
    JCard, 
    JCardBody, 
    JHeader, 
    JPaginacao, 
    JTable, 
    JTBody, 
    JTHead
} from "../../components/Components";
import { AuthContext } from "../../contexts/AuthContext";

export default function IndexPais() {
    const [dados, setDados] = useState<iData[]>([]);
    const [showSpinner, setShowSpinner] = useState(true);
    const [search, setSearch] = useState('');
    const [meta, setMeta] = useState<any>(null)
    const [links, setLinks] = useState<any>(null)
    const authContext = useContext(AuthContext);
    const  searchFilter =  (page: string | null = null) =>  {
        setShowSpinner(true);
        
        paisService.getAllPaginate(search, page).then( response => {
            setDados(response.data);
            setLinks(response.links);
            setMeta(response.meta);
            setShowSpinner(false);
            
        }).catch(err => {
            setShowSpinner(false);
        });
    }

    const handlePageChange =  (page: string | null = null) => {
        searchFilter(page)
    }

    useEffect(() => {
        searchFilter();
    }, [search]);

    return (
        <>
            <JCard className="shadow ">
                <JHeader  
                    search={search}
                    setSearch={setSearch}
                    showSpinner={showSpinner}
                    permissionCreate={authContext?.checkPermission('pais_create')}
                    permissionSearch={authContext?.checkPermission('pais_search')}
                />
                <JCardBody>
                    <JCaption>
                        Lista de países
                        <JPaginacao 
                            links={links ?? {}}
                            meta={meta ?? {}}
                            handlePageChange={handlePageChange} 
                            />
                    </JCaption>
                    <JTable >
                        <JTHead>
                            <tr>
                                <th style={{ width: '7rem' }}>
                                    Código IBGE
                                </th>
                                <th>
                                    Nome
                                </th>
                                
                            </tr>
                        </JTHead>
                        <JTBody>
                            {dados?.map((data) => (
                                <tr key={data.id}>
                                    <td>{data.cod_ibge}</td>
                                    <td>{data.name}</td>
                                </tr>
                            ))}                           
                        </JTBody>
                    </JTable>
                </JCardBody>
            </JCard>
        </>
    );
};

