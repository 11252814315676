import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import { InfMunCarregaInterface, infPercurso } from "../../interfaces";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store";
import { addListaMunicipiosCarregamento, removeMunicipioCarregamento } from "../../features/manifestoSlice";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MakeMessageError } from "../../components/Components";

export default function MunicipioCarrega(props: any) {
    const dispatch = useDispatch();
    const form = useSelector((state: RootState) => state.manifesto.manifesto);
    const selectMunicipios = useSelector((state: RootState) => state.manifesto.selectMunicipios);
    const listaMunicipiosCarregamento = useSelector((state: RootState) => state.manifesto.manifesto.infMunCarrega);
    const listaUfPercurso = useSelector((state: RootState) => state.manifesto.manifesto.infPercurso);
    const municipioCarregamentoRef = useRef<HTMLSelectElement>(null);
    const estadoInicialRef = props.estadoInicialRef;
    const errors = useSelector((state: RootState) => state.manifesto.listaErrors);
    // const errors = props.errors;

    const handleAddMunicipioCarregamento = () => {
        const municipioId = municipioCarregamentoRef.current?.value || '0';
        const nomeMunicipio = municipioCarregamentoRef.current?.options[municipioCarregamentoRef.current?.selectedIndex].text || '';
        
         if (municipioId) {

            if (estadoInicialRef.current) {
                const estadoInicial = estadoInicialRef.current.value || '';
                if (listaUfPercurso.find((estado: infPercurso) => estado.uf === estadoInicial)) {
                    alert('Remova a UF Inicial de UF de percurso');
                    return;
                }
            }

            if (municipioId === '0') {
                alert('Selecione um município');
                return;
            }

            if (form.infMunCarrega.find((municipio) => municipio.municipio_id === parseInt(municipioId))) {
                alert('Município já adicionado');
                return;
            }

            if (form.infMunCarrega.length === 50) {
                alert('Limite máximo de 50 locais de carregamento atingido.');
                return;
            }
                     
            dispatch(
                addListaMunicipiosCarregamento(
                    {
                        name: nomeMunicipio, 
                        municipio_id: parseInt(municipioId)
                    }
                )
            );
           
        }
    }

    const handleRemoveMunicipioCarregamento = (id: number) => {
        if (listaMunicipiosCarregamento.length > 0) {
            if (window.confirm('Deseja realmente remover?')) {
                dispatch(
                    removeMunicipioCarregamento(id)
                )
            }
        }
    }

    return (
        <Card className="card-primary card-outline">
            <div className="ps-3 pe-3 pt-3">
                <h6 className="">Municípios de carregamento <span className="text-dark">(50 no máximo)</span></h6>
                <Row className="g-2 pt-2">
                    <Col md={6}>
                        <Form.Group className="mb-2">
                            
                            <Form.Select  name="municipio_carregamento" id="municipio_carregamento"  ref={municipioCarregamentoRef} >
                                {selectMunicipios?.map((municipio: any) => (
                                    <option key={municipio.id} selected={municipio.capital === 1} value={municipio.id}>{municipio.name}</option>
                                ))}
                            </Form.Select>
                           
                        </Form.Group>
                    </Col>
                    <Col md={'auto'}>
                        <Form.Group className="mb-2">
                            <Button variant="primary" onClick={handleAddMunicipioCarregamento} > <FontAwesomeIcon icon={faPlus} /> Adicionar</Button>
                        </Form.Group>
                    </Col>
                    <Col md={'auto'}>
                   
                    </Col>
                </Row>
            </div>
            <Card.Body className="p-3">
                <Row>
                    <Col md>
                        
                        <MakeMessageError errors={errors} field="infMunCarrega" />
                        <Table striped bordered hover size="sm" responsive="sm">
                            <thead className="table-light">
                                <tr>
                                    <th className="text-center" style={{width: '2.5rem'}}>#</th>
                                    <th>Município</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {listaMunicipiosCarregamento?.map((municipio: InfMunCarregaInterface) => (
                                    <tr key={municipio.municipio_id}>
                                        
                                        <td className="text-center">
                                            <a href="#" className="text-danger" onClick={() => handleRemoveMunicipioCarregamento(municipio.municipio_id)}>
                                                <i className="fas fa-trash"></i>
                                            </a>
                                        </td>
                                        <td>{municipio.name}</td>
                                    </tr>
                                ))} 
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}
