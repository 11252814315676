import React, {useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faSave, faAngleDoubleRight, faAngleDoubleLeft} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { ProfileInterface as iData } from "../../interfaces";
import profileService from "../../services/ProfileService";
import { JAlerta } from "../../components/Components";
import  auxService  from "../../services/AuxService";
import { PermissionInterface } from "../../interfaces";

const initial = {
    id: '',
    name: '',
    permissions: [],
    active: true
}


const initialFieldsError = {
  name: [], 
}

export default function FormProfile() {
    const navigate = useNavigate()
    const { id } = useParams<{id: string}>();
    const [showError, setShowError] = useState(false);
    const [showMessageError, setShowMessageError] = useState('');
    const [errors, setErrors] = useState(initialFieldsError);
    const [data, setData] = useState<iData>(initial)
    const [disponiveis, setDisponiveis] = useState<PermissionInterface[]>([])
    const [dispFilter, setDispFilter] = useState<PermissionInterface[]>([])
    const [aplicados, setAplicados] = useState<PermissionInterface[]>([])
    const [apliFilter, setApliFilter] = useState<PermissionInterface[]>([])

    useEffect(() => {
        
        if (id) {
          profileService.get(id)
            .then(data => {
                setData(data);
                setDisponiveis([...data.not_permissions.map((name:string) => ({name}))] );
                setDispFilter([...data.not_permissions.map((name:string) => ({name}))]);
                
                setAplicados([...data.permissions.map((name:string) => ({name}))] );
                setApliFilter([...data.permissions.map((name:string) => ({name}))] );
            })

            .catch(err => navigate('/profile'))
        } else {
            auxService.getPermissions().then(data => {
                setDisponiveis(data)
                setDispFilter(data);
            })
        }
    }, [id])

    //handles
    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (id) {
            data.permissions = aplicados.map((permission) => permission.name);
            
            profileService.update(id, data).then( data => {
                navigate('/profile')
            }).catch(err => {
                
                if (err.response.status === 422) {
                    setErrors(err.response.data)
                }
                if (err.response.status === 403) {
                    setShowError(true)
                    setShowMessageError(err.response.data)
                }
                navigate(`/profile/${id}`)
            })
            return;
        } else {
            data.permissions = aplicados.map((permission) => permission.name);
            
            profileService.create(data).then( data => {
                navigate('/profile')
            }).catch(err => {
                
                if (err.response.status === 422) {
                    setErrors(err.response.data)
                }
                if (err.response.status === 403) {
                    setShowError(true)
                    setShowMessageError(err.response.data)
                }
                navigate("/profile/create")
            })
        }
    }

    const handleFilterDisponiveis = (e: any) => {
        setDispFilter([...disponiveis.filter(permission => permission.name.includes(e.target.value))])
    }
    const handleFilterAplicados = (e: any) => {
        setApliFilter([...aplicados.filter(permission => permission.name.includes(e.target.value))])
    }

    const handleChange = (e: any) => {
        const {name, value} = e.target;
        
        setData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    //functions
    const addPermission = (permission: PermissionInterface) => {
        setDisponiveis((prev) => prev.filter((p) => p.name !== permission.name));
        setDispFilter((prev) => prev.filter((p) => p.name !== permission.name));

        setAplicados((prev) => [...prev, permission]);
        setApliFilter((prev) => [...prev, permission]);
    };
    
    const removePermission = (permission: PermissionInterface) => {
        setDisponiveis((prev) => [...prev, permission]);
        setDispFilter((prev) => [...prev, permission]);

        setAplicados((prev) => prev.filter((p) => p.name !== permission.name));
        setApliFilter((prev) => prev.filter((p) => p.name !== permission.name));
    };

    return (
        <div className="">
            <JAlerta showError={showError} close={setShowError} messageError={showMessageError} /> 

            <Form onSubmit={handleSubmit} noValidate>
                <Card className="card-success card-outline">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <div className="my-0">
                            <Button variant={id ? 'success': 'primary'} type="submit"><FontAwesomeIcon icon={faSave} /> {id ? 'Salvar': 'Criar' }</Button>
                            <Link to="/profile" className="btn btn-secondary ms-1"> <FontAwesomeIcon icon={faArrowLeft} /> Voltar</Link>
                        </div>
                        <div className="h5 py-0 m-0 text-muted">{id ? 'Editar' : 'Novo'} Perfil</div>
                    </Card.Header>

                    <Card.Body>
                            <Row>
                          
                                <Col md>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Nome do perfil</Form.Label>
                                        <Form.Control maxLength={50} name="name" value={data.name} 
                                            type="text" onChange={handleChange}  />

                                        {errors.name  && (
                                            <ul className="errorfield">
                                                {errors.name.map((error, index) => (
                                                    <li key={index} className="text-danger">{error}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md className="">
                                        <h5>Permissões</h5>
                                        <hr className="mt-1" />
                                </Col>
                            </Row>

                            <Row>
                                <Col >
                                    <div className="d-flex justify-content-between align-items-center gap-2 mb-3 mx-2">
                                        <h5 className="text-muted">Disponíveis</h5>
                                        <Form.Control type="search"  placeholder="Buscar" onChange={(e) => handleFilterDisponiveis(e)}/>

                                    </div>
                                    <Form.Select multiple id="multiSelect" style={{height: '200px'}} 
                                        onDoubleClick={(e: React.MouseEvent<HTMLSelectElement>) => {
                                            addPermission(disponiveis.find((p) => p.name === (e.target as HTMLSelectElement).value) as PermissionInterface)
                                        }}
                                    >
                                    {dispFilter.map((permission) => (
                                        <option value={permission.name} key={permission.name}>
                                        {permission.name}
                                        </option>
                                    ))}
                                    </Form.Select>
                                   
                                </Col>

                                <Col md={'auto'} className="d-flex flex-column align-items-center justify-content-center">
                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        className="mb-2 mt-2"
                                        disabled={dispFilter.length === 0}
                                        onClick={() =>
                                            dispFilter.forEach((permission) => addPermission(permission))
                                        }
                                    >
                                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                                    </Button>

                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        className="mb-2"
                                        disabled={apliFilter.length === 0}
                                        onClick={() => apliFilter.forEach((permission) => removePermission(permission))}
                                    >
                                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                                    </Button>
                                </Col>

                                <Col >
                                    <div className="d-flex justify-content-between align-items-center gap-2 mb-3 mx-2">
                                        <h5 className="text-muted">Aplicadas</h5>
                                        <Form.Control type="search"  placeholder="Buscar" onChange={(e) => handleFilterAplicados(e)}/>

                                    </div>
                                    <Form.Select multiple id="multiSelect" style={{height: '200px'}}
                                        onDoubleClick={(e: React.MouseEvent<HTMLSelectElement>) => {
                                        removePermission(aplicados.find((p) => p.name === (e.target as HTMLSelectElement).value) as PermissionInterface)
                                    }}
                                    >
                                    {apliFilter.map((permission) => (
                                        <option value={permission.name} key={permission.name}>
                                        {permission.name}
                                        </option>
                                    ))}
                                    </Form.Select>
                                   
                                </Col>
                            </Row>
                    </Card.Body>
                </Card>
            </Form>
        </div>
    );
}
