import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { removeCTe, addCTe } from "../../features/manifestoSlice"
import { handleNumberOnly, isNumber } from "../../utils/utils";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { MensagemModal } from "../../components/Components";

const CTes: React.FC = () => {
    const munDescarga = useSelector((state: RootState) => state.manifesto.manifesto.infMunDescarga);
    const selectMunicipiosDescarregamento = useSelector((state: RootState) => state.manifesto.selectMunicipiosDescarregamentos);
    const munCTeSelectRef = useRef<HTMLSelectElement>(null);
    const [showAlert, setShowAlert] = useState(false); 
    const [messageAlert, setMessageAlert] = useState('');
    const dispatch = useDispatch();
    const [notaConhecimento, setNotaConhecimento] = useState({cte_numero: '', cte_codigo_barras: '', cte_municipio_id: '0' });
    const handleCTeChange = (e: any) => {
        const {name, value} = e.target;
        setNotaConhecimento((prev) => ({...prev, [name]: value }));
    }

    const validaPreenchimentoCTe = () => {
        if (notaConhecimento.cte_numero === "") {
            setMessageAlert('Informe uma chave CTe.')
            setShowAlert(true)
            return false;
        }

        if (isNumber(notaConhecimento.cte_numero)) {
            setMessageAlert('Chave CT-e aceita somente números.')
            setShowAlert(true)
            return false;
        }
        
        if (notaConhecimento.cte_codigo_barras?.trim() !== "") {
            if (isNumber(notaConhecimento.cte_codigo_barras)) {
                setMessageAlert('Seg. Cod. Barras aceita somente números.')
                setShowAlert(true)
                return false;
            }
        }

        if (notaConhecimento.cte_numero.substring(20,22) != '57') {
            setMessageAlert('Chave informada não é uma CT-e.')
            setShowAlert(true)
            return false;
        }

        if (notaConhecimento.cte_numero.length != 44) {
            setMessageAlert('Chave deve conter 44 caracteres.')
            setShowAlert(true)
            return false;
        }

        if (munDescarga.flatMap((item) => item.infCTe).find(item => item.chCTe === notaConhecimento.cte_numero)) {
            setMessageAlert('Chave já foi adicionada.')
            setShowAlert(true)
            return false; 
        }

        setMessageAlert('')
        setShowAlert(false)
        return true;
    }

    const handleRemoveCTe = (municipio_id: number, chCTe: string) => {
        if (window.confirm('Deseja realmente remover?')) {
             dispatch(removeCTe({ municipio_id: municipio_id, chCTe : chCTe }));
        }
    }
    
    const handleAddCTe = () => {
        if (munCTeSelectRef.current && munCTeSelectRef.current?.value !== "") {
            const value = munCTeSelectRef?.current?.value || '0'
            const nomeMunicipio = munCTeSelectRef.current?.options[munCTeSelectRef.current?.selectedIndex].text || '';
            
            if (validaPreenchimentoCTe()) {

                dispatch(addCTe({
                    chCTe: notaConhecimento.cte_numero, 
                    municipio_id: parseInt(notaConhecimento.cte_municipio_id),
                    name: nomeMunicipio,
                    SegCodBarra: notaConhecimento.cte_codigo_barras
                }));
        
                setNotaConhecimento({ cte_codigo_barras: '', cte_numero: '', cte_municipio_id: value })
            }
        
        } else {
            window.alert("Selecione um município.");
            return;
        }
    }

    useEffect(() => {
        if (munDescarga?.length > 0) {
            setNotaConhecimento({...notaConhecimento, cte_municipio_id: munDescarga[0].municipio_id?.toString()})
        }
    },[munDescarga]);

    return (<>
        <Card>
            <Card.Body >
                <Row className="g-2">
                    <Col md>
                        <Form.Group >
                            <Form.Label>*Municipios</Form.Label>
                            <Form.Select name="cte_municipio_id" value={notaConhecimento.cte_municipio_id} 
                                onChange={handleCTeChange} ref={munCTeSelectRef}>
                                {selectMunicipiosDescarregamento?.map(item => {
                                    return (<option value={item.id}>{item.name}</option>);
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col md>
                        <Form.Group >
                            <Form.Label>*Chave CT-e</Form.Label>
                            <Form.Control name="cte_numero" value={notaConhecimento.cte_numero} 
                                onKeyUp={handleNumberOnly} type="text" onChange={handleCTeChange}/>
                        </Form.Group>
                    </Col>

                    <Col md>
                        <Form.Group >
                            <Form.Label>Seg. Cod. Barras</Form.Label>
                            <Form.Control name="cte_codigo_barras" value={notaConhecimento.cte_codigo_barras} 
                                onKeyUp={handleNumberOnly} type="text" onChange={handleCTeChange}/>
                        </Form.Group>
                    </Col>

                    <Col md={'auto'} className="d-flex align-items-end">
                        <Button variant="primary" onClick={handleAddCTe}>
                            <FontAwesomeIcon icon={faPlus}/> Adicionar
                        </Button>
                    </Col>
                </Row>

                <h6 className="my-3"><span className="text-dark">(100 no máximo)</span></h6>

                <Table striped bordered hover size="sm">
                    <thead className="table-light">
                        <tr>
                            <th>#</th>
                            <th>Municipio</th>
                            <th>Chave CT-e</th>
                            <th>Seg. Cod. Barras</th>
                        </tr>
                    </thead>
                    <tbody>
                    {munDescarga?.map((item) => (
                            <>
                                {item.infCTe?.map((cte) => {
                                    return (
                                        <tr key={item?.municipio_id}>
                                            <td className="text-center">
                                                <a href="#" className="text-danger" onClick={() => handleRemoveCTe(item.municipio_id, cte?.chCTe)}>
                                                    <i className="fas fa-trash"></i>
                                                </a>
                                            </td>
                                            <td>{item?.name}</td>
                                            <td>{cte?.chCTe}</td>
                                            <td>{cte?.SegCodBarra}</td>
                                        </tr>
                                    )
                                })}
                            </>
                        ))}
                    </tbody>
                </Table>                   
            </Card.Body>

        </Card>
        <MensagemModal
            show={showAlert}
            handleClose={() => setShowAlert(false)}
            mensagem={messageAlert}
        />
    </>)
}

export default CTes;
