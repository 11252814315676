import api from "../api/api";

class CondutorService {
    async getAllPaginate(search: string, page: string | null) {
        let q = '';
        if (search?.length > 0) {
            q = `?q=${search}`;
            q += page ? `&page=${page}` : "";
            return await api.get(`/condutor${q}`).then(r => r.data);
        } else {
            if (page) {
                return await api.get(`/condutor?page=${page}`).then(r => r.data);
            } else {
                return await api.get(`condutor`).then(r => r.data);
            }
        }
    }

    async get(id: string) {
        return await api.get(`/condutor/${id}`).then(response => response.data);
    }

    async create(data: any) {
        return await api.post("/condutor", data).then(response => response.data);
    }

    async update(id: string, data: any) {
        return await api.put(`/condutor/${id}`, data).then(response => response.data);
    }

    async delete(id: string) {
        return await api.delete(`/condutor/${id}`).then(response => response.data);
    }
}

const condutorService = new CondutorService();

export default condutorService;