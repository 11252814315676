import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

export default function InfoManifesto(props: any) {
    const formManifesto = useSelector((state: RootState) => state.manifesto.manifesto );
    return (
        <>
            <Row>
                <Col md>
                    <Form.Group className="mb-3">
                        <Form.Label>Informações adicionais do interesse do fisco</Form.Label>
                        <Form.Control name="infadfisco"  as="textarea" rows={6} maxLength={2000} 
                            value={formManifesto.infadfisco} onChange={props.handleChange}>

                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>   
            <Row>
                <Col md>
                    <Form.Group>
                        <Form.Label>Informações complementares do interesse do contribuinte</Form.Label>
                        <Form.Control name="infcpl"  as="textarea" rows={6} maxLength={5000} 
                            value={formManifesto.infcpl} onChange={props.handleChange}>

                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>   
        </>
    )
}