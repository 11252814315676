import { Tab, Tabs } from "react-bootstrap";
import Condutores from "./Condutores";
import Veiculo from "./Veiculo";

export default function VeicTracao() {
    return (
        <Tabs defaultActiveKey="rodo_condutor"  id="tab-veictracao" className="mb-3">
            <Tab eventKey="rodo_condutor" title="Condutores" className="px-2">
                <Condutores/>
            </Tab>
            <Tab eventKey="rodo_veiculo" title="Dados do veículo" className="">
                <Veiculo/>
            </Tab>
        </Tabs>
    )
}