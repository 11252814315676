import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "../contexts/AuthContext";
import NotFound from "../pages/NotFound";
import Login from "../pages/login/Login";
import Home from "../pages/Home";
import Layout from "../pages/Layout";

import IndexPais from "../pages/pais";
import IndexEstado from "../pages/estado";
import IndexMunicipio from "../pages/municipio";
import IndexCondutor from "../pages/condutor";
import FormCondutor from "../pages/condutor/form";
import IndexManifesto from "../pages/manifesto";
import IndexProfile from "../pages/profile";
import FormProfile from "../pages/profile/form";
import IndexUser from "../pages/user";
import FormUser from "../pages/user/form";
import IndexPermission from "../pages/permission";
import IndexEmpresa from "../pages/empresa";
import FormEmpresa from "../pages/empresa/form";
import RegisterForm from "../pages/register/form";
import IndexLicenca from "../pages/licenca";
import FormLicenca from "../pages/licenca/form";
import FormManifesto from "../pages/manifesto/form";

const Rotas = () => {
    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route path="/login" element={<Login/>} />
                    <Route path="/register" element={<RegisterForm/>} />                   
                    <Route path="/" element={<Layout><Home/></Layout>} />
                    <Route path="/pais" element={<Layout><IndexPais/></Layout>} />
                    <Route path="/estado" element={<Layout><IndexEstado/></Layout>} />
                    <Route path="/municipio" element={<Layout><IndexMunicipio/></Layout>} />
                    <Route path="/condutor" element={<Layout><IndexCondutor/></Layout>} />
                    <Route path="/condutor/create" element={<Layout><FormCondutor/></Layout>} />
                    <Route path="/condutor/:id" element={<Layout><FormCondutor/></Layout>} />
                    <Route path="/manifesto" element={<Layout><IndexManifesto/></Layout>} />
                    <Route path="/profile" element={<Layout><IndexProfile/></Layout>} />                   
                    <Route path="/profile/create" element={<Layout><FormProfile/></Layout>} />                   
                    <Route path="/profile/:id" element={<Layout><FormProfile/></Layout>} />                   
                    <Route path="/user" element={<Layout><IndexUser/></Layout>} />          
                    <Route path="/user/create" element={<Layout><FormUser/></Layout>} />                   
                    <Route path="/user/:id" element={<Layout><FormUser/></Layout>} />          
                    <Route path="/empresa" element={<Layout><IndexEmpresa/></Layout>} />                   
                    <Route path="/empresa/create" element={<Layout><FormEmpresa/></Layout>} />                   
                    <Route path="/empresa/:id" element={<Layout><FormEmpresa/></Layout>} />          
                    <Route path="/permission" element={<Layout><IndexPermission/></Layout>} />                   
                    <Route path="/licenca" element={<Layout><IndexLicenca/></Layout>} />                   
                    <Route path="/licenca/create" element={<Layout><FormLicenca/></Layout>} />                   
                    <Route path="/manifesto/create" element={<Layout><FormManifesto/></Layout>} />                   
                    <Route path="/manifesto/:id" element={<Layout><FormManifesto/></Layout>} />                   
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </AuthProvider>

            
        </Router>
    );
}

export default Rotas;