import { Col, Form, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store";
import { chamgeProdutoPredominante, clearError } from "../../features/manifestoSlice";
import { MakeMessageError } from "../../components/Components";

export default function ProdutoPredominante(props: any) {
    const formManifesto = useSelector((state: RootState) => state.manifesto.manifesto );
    const errors = useSelector((state: RootState) => state.manifesto.listaErrors);
    const dispatch = useDispatch();
    
    const handleProdPred = (e: any) => {
        const { name, value } = e.target;
        dispatch(chamgeProdutoPredominante({[name]: value}));
        dispatch(clearError('prodPred_'+name))
    }

    return (<>
        <Row>
            <Col md={3}>
                <Form.Group className="mb-2">
                    <Form.Label>Tipo de carga</Form.Label>
                    <Form.Select name="tpcarga" value={formManifesto.prodPred.tpcarga} 
                        onChange={handleProdPred} 
                        // className={errorProdPred?.tpcarga && errorProdPred?.tpcarga.length > 0 ? 'is-invalid' : ''}
                        > 
                    <option key="0" value="0">Selecione</option>
                    <option key="01" value="01">01-Granel sólido</option>
                    <option key="02" value="02">02-Granel líquido</option>
                    <option key="03" value="03">03-Frigorificada</option>
                    <option key="04" value="04">04-Conteinerizada</option>
                    <option key="05" value="05">05-Carga geral</option>
                    <option key="06" value="06">06-Neogranel</option>   
                    <option key="07" value="07">07-Perigosa (granel sólido)</option>
                    <option key="08" value="08">08-Perigosa (granel líquido)</option>
                    <option key="09" value="09">09-Perigosa (carga frigorificada)</option>
                    <option key="10" value="10">10-Perigosa (conteinerizada)</option>
                    <option key="11" value="11">11-Perigosa (carga geral)</option>
                </Form.Select>

                <MakeMessageError errors={errors} field="prodPred_tpcarga"/>
                </Form.Group>
            </Col>
            
            <Col md={2}>
                <Form.Group className="mb-2">
                    <Form.Label>CEAN</Form.Label>
                    <Form.Control type="text" maxLength={14} name="cean" value={formManifesto.prodPred.cean} 
                        onChange={handleProdPred} 
                        // className={errorProdPred?.cean && errorProdPred?.cean.length > 0 ? 'is-invalid' : ''} 
                        />
                    <MakeMessageError errors={errors} field="prodPred_cean"/>

                </Form.Group>
            </Col>
            <Col md={2}>
                <Form.Group className="mb-2">
                    <Form.Label>NCM</Form.Label>
                    <Form.Control type="text" maxLength={8} name="ncm" value={formManifesto.prodPred.ncm} 
                        onChange={handleProdPred} 
                        // className={errorProdPred?.ncm && errorProdPred?.ncm.length > 0 ? 'is-invalid' : ''} 
                        />
                    <MakeMessageError errors={errors} field="prodPred_ncm"/>
                </Form.Group>
            </Col>
            <Col md>
                <Form.Group className="mb-2">
                    <Form.Label>Descrição do produto</Form.Label>
                    <Form.Control type="text" name="xprod" value={formManifesto.prodPred.xprod} 
                        onChange={handleProdPred} 
                        // className={errorProdPred?.xprod && errorProdPred?.xprod.length > 0 ? 'is-invalid' : ''} 
                        />
                    <MakeMessageError errors={errors} field="prodPred_xprod"/>
                </Form.Group>
            </Col>
        </Row>
    </>)
}