import { Tab, Tabs } from "react-bootstrap";

export default function Antt() {
    return (
        <Tabs defaultActiveKey="annt_rntrc"  id="tab-rodoviario" className="mb-3">
            
            <Tab eventKey="annt_rntrc" title="RNTRC">

            </Tab>

            <Tab eventKey="annt_ciot" title="CIOTs">

            </Tab>

            <Tab eventKey="annt_contratante" title="Contratantes">

            </Tab>

            <Tab eventKey="annt_valepedagio" title="Vale Pedágio">

            </Tab>
        
        </Tabs>

    )
}